import React, { useMemo } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { getEnvironment } from "./utils/getEnvironment";
import { RouterProvider } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/uk";
import { useCookies } from "react-cookie";
import { CircularProgress, createTheme, ThemeProvider } from "@mui/material";
import router from "./router";
import useWeglot from "./hooks/useWeglot";
import { AddressProvider } from "./contexts/AddressContext";
import { useInitializeApp } from "./hooks/useInitializeApp";
import { getAddressFromSubdomain } from "./utils/getAddress";
import BrandNotFound from "./components/BrandNotFound";

const environment = getEnvironment();

const Main = () => {
    useWeglot();
    const address = getAddressFromSubdomain() || "";
    const { initialized, notFound } = useInitializeApp(address);
    const [cookies] = useCookies(["app_theme"]);

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    primary: {
                        main: cookies.app_theme ?? "#F189AE",
                    },
                    secondary: {
                        main: "#FFFFFF",
                    },
                    info: {
                        main: (cookies.app_theme ?? "#F189AE") + "33",
                    },
                    warning: {
                        main: "#141414",
                    },
                },
                shadows: [
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                ],
            }),
        [cookies]
    );

    if (notFound) {
        return <BrandNotFound />;
    }

    if (!initialized) {
        return <CircularProgress />;
    }

    return (
        <ThemeProvider theme={theme}>
            <AddressProvider>
                <RouterProvider router={router} />
            </AddressProvider>
        </ThemeProvider>
    );
};

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <>
        <GoogleOAuthProvider clientId={environment.google_client_id}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk">
                <React.StrictMode>
                    <Main />
                </React.StrictMode>
            </LocalizationProvider>
        </GoogleOAuthProvider>
    </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
