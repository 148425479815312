import React, { useCallback, useEffect, useState } from "react";
import Button, { ButtonProps } from "./Button";
import {
    Box,
    ButtonBase,
    Grid,
    Modal,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import ColoredIcon from "./ColoredIcon";
import ArrowLeft from "../img/Arrow_Left.svg";
import ArrowRight from "../img/Arrow_Right.svg";
import { useCookies } from "react-cookie";
import Schedule, { FreeDay } from "../api/Schedule";
import { parseWithOffset } from "../utils/dayjsUtils";

interface Props {
    children?: string;
    onCancel?: () => void;
    onSave?: (
        dateTime: Dayjs,
        close: () => void,
        setLoading: (val: boolean) => void
    ) => void;
    title?: string;
    cancelText?: string;
    saveText?: string;
    address: string;
    duration: number;
    button: ButtonProps;
}

const DateTimePicker = (props: Props) => {
    const [open, setOpen] = useState(false); // Modal control
    const [loading, setLoading] = useState(false);

    const { children, button, title, cancelText, saveText, address, duration } =
        props;

    const onCancel = useCallback(() => {
        setOpen(false);
        props.onCancel?.();
    }, [props]);

    const onSave = useCallback(
        (dateTime: Dayjs) => {
            props.onSave?.(dateTime, () => setOpen(false), setLoading);
        },
        [props]
    );

    return (
        <>
            <Button
                {...button}
                children={children}
                onClick={() => setOpen(true)}
            />
            <Modal open={open} onClose={() => {}}>
                <DateTimeModal
                    onCancel={onCancel}
                    onSave={onSave}
                    title={title}
                    cancelText={cancelText}
                    saveText={saveText}
                    loading={loading}
                    address={address}
                    duration={duration}
                />
            </Modal>
        </>
    );
};

interface ModalProps {
    address: string;
    duration: number;
    onCancel: () => void;
    onSave: (dateTime: Dayjs) => void;
    title?: string;
    cancelText?: string;
    saveText?: string;
    loading?: boolean;
}

const DateTimeModal = (props: ModalProps) => {
    const theme = useTheme();

    const [cookies] = useCookies(["client_location_id"]);
    const { client_location_id } = cookies;

    const {
        address,
        duration,
        onCancel,
        title,
        cancelText,
        saveText,
        loading,
    } = props;

    const today = dayjs.utc().startOf("day");
    const [currentDate, setCurrentDate] = useState(today.startOf("month"));
    const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>();
    const [content, setContent] = useState<(Dayjs | undefined)[]>([]);

    const [availableDays, setAvailableDays] = useState<FreeDay[]>([]);

    const [availableTime, setAvailableTime] = useState<Dayjs[]>([]);
    const [selectedTime, setSelectedTime] = useState<Dayjs | undefined>();

    useEffect(() => {
        if (duration === null) return;

        Schedule.getFreeSlots(
            address,
            client_location_id,
            currentDate.get("year"),
            currentDate.get("month") + 1, // 1-indexed month
            duration
        )
            .then((days) => {
                setAvailableDays(days || []);
            })
            .catch(console.error);
    }, [address, client_location_id, duration, currentDate]);

    useEffect(() => {
        setSelectedTime(undefined);
        if (selectedDate === undefined) {
            setAvailableTime([]);
            return;
        }

        const selectedDaySlots = availableDays.find(
            (day) => day.date === selectedDate.format("YYYY-MM-DD")
        );

        if (!selectedDaySlots || selectedDaySlots.freeSlots.length === 0) {
            setAvailableTime([]);
            setSelectedTime(undefined);
            return;
        }

        const timeSlots = selectedDaySlots.freeSlots.map(
            (slot) => parseWithOffset(slot.start) // Store the raw Dayjs object
        );

        setAvailableTime(timeSlots);
        //setSelectedTime(timeSlots[0]);
    }, [availableDays, selectedDate, duration]);

    useEffect(() => {
        const startOfMonth = currentDate.startOf("month");

        const daysInMonth = currentDate.daysInMonth();
        //console.log("Days in Month:", daysInMonth);

        const updatedContent: (Dayjs | undefined)[] = [];

        // Add placeholders for days of the week before the first day
        const startDayOfWeek = (startOfMonth.day() + 6) % 7; // Clunky fix to start the calendar from Monday
        //console.log("Start Day of Week:", startDayOfWeek);

        for (let i = 0; i < startDayOfWeek; i++) {
            updatedContent.push(undefined);
        }

        // Add all actual days of the month
        for (let day = 1; day <= daysInMonth; day++) {
            updatedContent.push(startOfMonth.clone().set("date", day));
        }

        setContent(updatedContent);
    }, [currentDate]);

    const disabledDates = useCallback(
        (date: Dayjs) => {
            if (date.isBefore(today)) {
                return true;
            }
            const dayData = availableDays.find(
                (day) => day.date === date.format("YYYY-MM-DD")
            );

            return !dayData || dayData.freeSlots.length === 0;
        },
        [today, availableDays]
    );

    const onSave = useCallback(() => {
        if (
            selectedDate === undefined ||
            disabledDates(selectedDate) ||
            selectedTime === undefined
        ) {
            return;
        }

        props.onSave(selectedTime);
    }, [selectedDate, selectedTime, props, disabledDates]);

    return (
        <Stack
            position="absolute"
            top="50%"
            left="50%"
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            width="85%"
            height="90%"
            padding={2}
            bgcolor="white"
            borderRadius="14px"
            boxShadow="0px 4px 17px 0px #0000000A"
            sx={{
                transform: "translate(-50%, -50%)",
            }}
        >
            <Typography variant="h6" component="h2">
                {title ?? "Select date"}
            </Typography>
            <Stack
                width="100%"
                height="100%"
                alignItems="center"
                margin={2}
                paddingBottom={5}
                overflow="scroll"
                spacing={2}
            >
                <Stack
                    direction="row"
                    width="90%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography
                        fontWeight={600}
                        fontSize="14px"
                        lineHeight="18.2px"
                        color="#1A1A1A"
                        sx={{ opacity: "60%" }}
                    >
                        {currentDate.format("MMMM, YYYY")}
                    </Typography>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                    >
                        <ButtonBase
                            sx={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "12px",
                            }}
                            disabled={availableDays.length === 0}
                            onClick={() => {
                                setAvailableDays([]);
                                setSelectedDate(undefined);
                                setCurrentDate(
                                    currentDate.subtract(1, "month")
                                );
                            }}
                        >
                            <ColoredIcon
                                icon={ArrowLeft}
                                size="100%"
                                color="black"
                            />
                        </ButtonBase>
                        <ButtonBase
                            sx={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "12px",
                            }}
                            disabled={availableDays.length === 0}
                            onClick={() => {
                                setAvailableDays([]);
                                setSelectedDate(undefined);
                                setCurrentDate(currentDate.add(1, "month"));
                            }}
                        >
                            <ColoredIcon
                                icon={ArrowRight}
                                size="100%"
                                color="black"
                            />
                        </ButtonBase>
                    </Stack>
                </Stack>
                <Box
                    width="100%"
                    height="1px"
                    minHeight="1px"
                    bgcolor={theme.palette.primary.main}
                />
                <Grid container columns={7} width="95%" rowSpacing={1}>
                    {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map((x) => (
                        <Grid
                            item
                            xs={1}
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography
                                fontWeight={700}
                                fontSize="14px"
                                lineHeight="18.2px"
                                sx={{ opacity: "60%" }}
                            >
                                {x}
                            </Typography>
                        </Grid>
                    ))}
                    {content.map((x) => (
                        <Grid
                            item
                            xs={1}
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {x === undefined ? (
                                <Box width="48px" height="48px" />
                            ) : (
                                <DayView
                                    day={x}
                                    selected={
                                        x.format("DD.MM.YYYY") ===
                                        selectedDate?.format("DD.MM.YYYY")
                                    }
                                    current={
                                        x.format("DD.MM.YYYY") ===
                                        today.format("DD.MM.YYYY")
                                    }
                                    onClick={() => setSelectedDate(x)}
                                    disabled={disabledDates(x)}
                                />
                            )}
                        </Grid>
                    ))}
                </Grid>
                <Stack
                    direction="row"
                    paddingTop={2}
                    width="90%"
                    alignItems="center"
                    justifyContent="flex-start"
                >
                    <Typography
                        fontWeight={600}
                        fontSize="14px"
                        lineHeight="18.2px"
                    >
                        Available time
                    </Typography>
                </Stack>
                <Box
                    width="100%"
                    height="1px"
                    minHeight="1px"
                    bgcolor={theme.palette.primary.main}
                />
                {availableTime.length === 0 ? (
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        width="85%"
                        padding={2}
                        bgcolor="white"
                        borderRadius="14px"
                        boxShadow="0px 4px 17px 0px #0000000A"
                    >
                        <Typography
                            fontWeight={400}
                            fontSize="18px"
                            lineHeight="23.4px"
                            textAlign="center"
                        >
                            No available time on this date
                        </Typography>
                    </Stack>
                ) : (
                    <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                        width="85%"
                        padding={2}
                        bgcolor="white"
                        borderRadius="14px"
                        boxShadow="0px 4px 17px 0px #0000000A"
                        height="100px"
                        overflow="scroll"
                    >
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-start"
                            rowGap={2}
                            height="100%"
                            width="100%"
                        >
                            {availableTime.map((time, index) => (
                                <Grid
                                    key={index}
                                    item
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    width="72px"
                                    xs={3}
                                >
                                    <ButtonBase
                                        sx={{
                                            width: "72px",
                                            height: "48px",
                                            borderRadius: "14px",
                                            marginX: "5px",
                                            bgcolor: theme.palette.primary.main,
                                            opacity: time.isSame(
                                                selectedTime,
                                                "minute"
                                            )
                                                ? "100%"
                                                : "40%",
                                        }}
                                        onClick={() => {
                                            setSelectedTime(time);
                                        }}
                                    >
                                        <Typography
                                            fontWeight={700}
                                            fontSize="18px"
                                            lineHeight="23.4px"
                                            textAlign="center"
                                            color="white"
                                        >
                                            {time.format("HH:mm")}
                                        </Typography>
                                    </ButtonBase>
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                )}
            </Stack>
            <Stack direction="row" width="100%" spacing={2}>
                <Button
                    variant="medium"
                    fullWidth
                    onClick={onCancel}
                    disabled={loading}
                >
                    {cancelText ?? "Cancel"}
                </Button>
                <Button
                    variant="medium"
                    fullWidth
                    onClick={() => onSave()}
                    disabled={
                        loading ||
                        selectedDate === undefined ||
                        disabledDates(selectedDate) ||
                        selectedTime === undefined
                    }
                >
                    {saveText ?? "Save"}
                </Button>
            </Stack>
        </Stack>
    );
};

const DayView = (props: {
    day: Dayjs;
    selected: boolean;
    current: boolean;
    onClick: () => void;
    disabled?: boolean;
}) => {
    const theme = useTheme();

    const { day, selected, current, onClick, disabled } = props;

    if (selected) {
        return (
            <ButtonBase
                sx={{
                    width: "48px",
                    minWidth: "48px",
                    maxWidth: "48px",
                    height: "48px",
                    borderRadius: "14px",
                    bgcolor: theme.palette.primary.main,
                    opacity: disabled ? "20%" : "100%",
                }}
            >
                <Typography
                    fontWeight={700}
                    fontSize="18px"
                    lineHeight="23.4px"
                    color={disabled ? "black" : "white"}
                >
                    {day.get("date")}
                </Typography>
            </ButtonBase>
        );
    }
    return (
        <ButtonBase
            sx={{
                width: "48px",
                minWidth: "48px",
                maxWidth: "48px",
                height: "48px",
                borderRadius: "14px",
                border: current
                    ? `1px solid ${theme.palette.primary.main}`
                    : "none",
                opacity: disabled ? "20%" : "100%",
            }}
            onClick={onClick}
        >
            <Typography fontWeight={400} fontSize="18px" lineHeight="23.4px">
                {day.get("date")}
            </Typography>
        </ButtonBase>
    );
};

export default DateTimePicker;
