import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    CircularProgress,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import Bg from "./Bg";
import GalleryImage from "./GalleryImage";
import TabHeader from "./TabHeader";
import User from "../api/User";
import Booking from "../api/Booking";
import Service from "../api/Service";
import ColoredIcon from "./ColoredIcon";
import WalletIcon from "../img/Wallet.svg";
import ClockIcon from "../img/Clock.svg";
import dayjs from "dayjs";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useAddress } from "../contexts/AddressContext";

const tagsColors = [
    {
        tag: "Ordinary",
        color: "#6D7475",
    },
    {
        tag: "Brilliant",
        color: "#CF5034",
    },
    {
        tag: "vip",
        color: "#ECC142",
    },
    {
        tag: "Good",
        color: "#469D4A",
    },
];

export const loader = async (request: any) => {
    const booking_id = await request.params.booking_id;
    return { booking_id };
};

const ServiceInfo = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const address = useAddress();
    const { booking_id } = useLoaderData() as {
        booking_id: string;
    };

    const [cookies] = useCookies(["client_id", "client_location_id"]);
    const { client_id, client_location_id } = cookies;

    const [booking, setBooking] = useState<Booking | undefined>();
    const [service, setService] = useState<Service | undefined>();
    const [user, setUser] = useState<User | undefined>();
    const [image, setImage] = useState<string | null | undefined>();

    useEffect(() => {
        if (!client_id) {
            navigate(`/${address}/`);
            return;
        }

        Booking.info(client_id, address, client_location_id, booking_id)
            .then((booking) => {
                if (!booking) return;
                setBooking(booking);
                Service.info(address, booking.service)
                    .then(setService)
                    .catch(console.error);
                User.info(client_id, address)
                    .then((res) => {
                        setUser(res);
                        setImage(res?.image);
                    })
                    .catch(console.error);
            })
            .catch((err) => {
                console.error(err);
                navigate("/calendar");
            });
    }, [client_id, client_location_id, address, booking_id, navigate]);

    const onBack = useCallback(() => {
        if (window.history.length > 1) {
            navigate(-1);
        } else {
            navigate(`/${address}/notifications`);
        }
    }, [address, navigate]);

    if (!booking || !user || !service) return <CircularProgress />;
    return (
        <Stack height="100vh" width="100%" alignItems="center">
            <Bg />
            <TabHeader name="Appointment" onClose={onBack} />
            <Stack height="100%" width="100%" alignItems="center" spacing={2}>
                <Stack
                    direction="row"
                    alignItems="center"
                    width="85%"
                    spacing={2}
                >
                    {image ? (
                        <GalleryImage
                            image={image}
                            width="82px"
                            height="82px"
                            borderRadius="82px"
                        />
                    ) : (
                        <Box
                            width="82px"
                            height="82px"
                            borderRadius="82px"
                            bgcolor={theme.palette.primary.main}
                        />
                    )}
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Typography
                            fontWeight={600}
                            fontSize="18px"
                            lineHeight="23.4px"
                        >
                            {user.name}
                        </Typography>
                        <Stack
                            direction="row"
                            width="100%"
                            alignItems="center"
                            spacing={1}
                        >
                            <Stack
                                direction="row"
                                width="100px"
                                alignItems="center"
                            ></Stack>
                        </Stack>
                        <Stack
                            direction="row"
                            width="100%"
                            alignItems="center"
                            spacing={1}
                        >
                            <Stack
                                bgcolor={
                                    tagsColors.find(
                                        (x) => x.tag === user.status
                                    )?.color ?? theme.palette.primary.main
                                }
                                paddingX={1}
                                paddingY={0.5}
                                borderRadius="14px"
                                border="1px solid white"
                                width="fit-content"
                            >
                                <Typography
                                    fontWeight={400}
                                    fontSize="12px"
                                    lineHeight="14.52px"
                                    color="white"
                                >
                                    {user.status}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <ServiceItem booking={booking} name={service.name} />
                <Stack
                    direction="row"
                    width="85%"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0.5}
                    >
                        <Typography
                            fontWeight={400}
                            fontSize="40px"
                            lineHeight="48px"
                        >
                            {dayjs(booking.start).get("date")}
                        </Typography>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={-0.5}
                        >
                            <Typography
                                fontWeight={600}
                                fontSize="18px"
                                lineHeight="23.4px"
                                sx={{ opacity: "60%" }}
                            >
                                {dayjs(booking.start).format("MMMM")}
                            </Typography>
                            <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="18.2px"
                                sx={{ opacity: "60%" }}
                            >
                                {dayjs(booking.start).format("dddd")}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography
                            fontWeight={600}
                            fontSize="18px"
                            lineHeight="23.4px"
                            color={theme.palette.primary.main}
                        >
                            {dayjs(booking.start).format("HH:mm")}
                        </Typography>
                        <Typography
                            fontWeight={600}
                            fontSize="18px"
                            lineHeight="23.4px"
                            color={theme.palette.primary.main}
                        >
                            —
                        </Typography>
                        <Typography
                            fontWeight={600}
                            fontSize="18px"
                            lineHeight="23.4px"
                            color={theme.palette.primary.main}
                        >
                            {dayjs(booking.end).format("HH:mm")}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

const ServiceItem = (props: { booking: Booking; name: string }) => {
    const theme = useTheme();

    const { booking, name } = props;

    const bg = theme.palette.primary.main;
    const fg = "white";

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="95%"
            height="100px"
            borderRadius="14px"
            boxShadow="0px 4px 17px 0px rgba(0, 0, 0, 0.04)"
            bgcolor={bg}
        >
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                paddingLeft={2}
                height="70%"
            >
                <Typography
                    fontWeight={500}
                    fontSize="16px"
                    lineHeight="16px"
                    color={fg}
                >
                    {name}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <ColoredIcon
                            icon={WalletIcon}
                            color={fg}
                            size="20px"
                            style={{ opacity: "50%" }}
                        />
                        <Typography
                            fontWeight={700}
                            fontSize="16px"
                            lineHeight="24px"
                            color={fg}
                        >
                            € {booking.price}
                        </Typography>
                    </Stack>
                    <FormattedTime time={booking.duration} fg={fg} />
                </Stack>
            </Stack>
        </Stack>
    );
};

const FormattedTime = (props: { time: number; fg: string }) => {
    const { time, fg } = props;

    const hours = Math.floor(time / 60);
    const mins = Math.floor(time % 60);

    return (
        <Stack direction="row" alignItems="flex-end" spacing={1}>
            <ColoredIcon
                icon={ClockIcon}
                color={fg}
                size="20px"
                style={{ opacity: "50%", paddingBottom: 2 }}
            />

            {hours > 0 ? (
                <Stack direction="row" alignItems="flex-end" spacing={0.5}>
                    <Typography
                        fontWeight={400}
                        fontSize="16px"
                        lineHeight="24px"
                        color={fg}
                    >
                        {hours}
                    </Typography>
                    <Typography
                        fontWeight={400}
                        fontSize="14px"
                        lineHeight="21px"
                        color={fg}
                    >
                        h
                    </Typography>
                </Stack>
            ) : undefined}
            <Stack direction="row" alignItems="flex-end" spacing={0.5}>
                <Typography
                    fontWeight={400}
                    fontSize="16px"
                    lineHeight="24px"
                    color={fg}
                >
                    {mins}
                </Typography>
                <Typography
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight="21px"
                    color={fg}
                >
                    min
                </Typography>
            </Stack>
        </Stack>
    );
};

export default ServiceInfo;
