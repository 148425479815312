import App from "./App";
import PageError from "./components/PageError";
import { createBrowserRouter } from "react-router-dom";
import Home from "./components/Home";
import ServicesList from "./components/Booking/ServicesList";
import ServiceListInfo, {
    loader as serviceListLoader,
} from "./components/Booking/ServiceInfo";
import ServiceDatePicker, {
    loader as serviceDatePickerLoader,
} from "./components/Booking/DateTimePicker";
import ServiceConfirmation, {
    loader as serviceConfirmationLoader,
} from "./components/Booking/Confirmation";
import Done from "./components/Booking/Done";
import ClientChat from "./components/ClientChat";
import Profile from "./components/Profile/Profile";
import AboutUs from "./components/MenuTabs/AboutUs";
import Contacts from "./components/MenuTabs/Contacts";
import ChangePassword from "./components/MenuTabs/ChangePassword";
import Login from "./components/Login/Login";
import SignUp from "./components/Login/SignUp";
import NotificationsList from "./components/NotificationsList";
import React from "react";
import ServiceInfo, {
    loader as serviceInfoLoader,
} from "./components/ServiceInfo";
import Redirect, { loader as redirectLoader } from "./components/Redirect";
import CreatePassword, {
    loader as createPasswordLoader,
} from "./components/CreatePassword";
import HealthCheckPage from "./components/HealthCheckPage";
import NotificationDetails from "./components/NotificationDetails";
import BrandsList from "./components/BrandsList";

const router = createBrowserRouter([
    {
        path: "/brandslist",
        element: <BrandsList />,
    },
    {
        path: "/health/check",
        element: <HealthCheckPage />,
    },
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/redirect/:client_id",
        loader: redirectLoader,
        element: <Redirect />,
    },
    {
        path: "/link/:rkey",
        loader: createPasswordLoader,
        element: <CreatePassword />,
    },

    {
        path: "/services",
        element: <ServicesList />,
    },
    {
        path: "/services/:service_id",
        loader: serviceListLoader,
        element: <ServiceListInfo />,
    },
    {
        path: "/services/:service_id/date",
        loader: serviceDatePickerLoader,
        element: <ServiceDatePicker />,
    },
    {
        path: "/services/:service_id/confirmation",
        loader: serviceConfirmationLoader,
        element: <ServiceConfirmation />,
    },
    {
        path: "/services/done",
        element: <Done />,
    },
    {
        path: "/chat",
        element: <ClientChat />,
    },
    {
        path: "/profile",
        element: <Profile />,
    },

    {
        path: "/menu/about",
        element: <AboutUs />,
    },
    {
        path: "/menu/contacts",
        element: <Contacts />,
    },
    {
        path: "/menu/password",
        element: <ChangePassword />,
    },

    {
        path: "/profile/login",
        element: <Login type="profile" />,
    },
    {
        path: "/profile/signup",
        element: <SignUp type="profile" />,
    },
    {
        path: "/chat/login",
        element: <Login type="chat" />,
    },
    {
        path: "/chat/signup",
        element: <SignUp type="chat" />,
    },
    {
        path: "/services/login",
        element: <Login type="services" />,
    },
    {
        path: "/services/signup",
        element: <SignUp type="services" />,
    },
    {
        path: "/notifications",
        element: <NotificationsList />,
    },
    {
        path: "/notifications/:notificationKey",
        element: <NotificationDetails />,
    },
    {
        path: "/appointment/:booking_id",
        loader: serviceInfoLoader,
        element: <ServiceInfo />,
    },
]);

export default router;
