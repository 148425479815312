import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";

const environment = getEnvironment();

interface FreeSlot {
    start: string; // Start time of the free slot in "HH:mm" format
    end: string; // End time of the free slot in "HH:mm" format
}

export interface FreeDay {
    date: string; // The date of the day (e.g., "YYYY-MM-DD")
    freeSlots: FreeSlot[]; // Array of free slots available on this date
}

export default class Schedule {
    /**
     * Retrieves the free slots for a specific month or day for a given location.
     * @param uuid - The user token for authentication.
     * @param brand - The brand ID.
     * @param location - The location ID.
     * @param year - The year of the desired date.
     * @param month - The month of the desired date (1-indexed).
     * @param duration - Duration of the appointment in minutes (query parameter).
     * @param day - (Optional) The day of the month to narrow down results.
     * @returns An array of free slots for each day in the specified time range.
     */
    public static readonly getFreeSlots = async (
        brand: string,
        location: string,
        year: number,
        month: number,
        duration: number,
        day?: number
    ): Promise<FreeDay[] | undefined> => {
        try {
            const endpoint = `${
                environment.api_url
            }/${brand}/location/${location}/free/${year}/${month}${
                day !== undefined ? `/${day}` : ""
            }`;

            const { data } = await axios.get(endpoint, {
                params: { duration }, // Sending duration as a query parameter
            });

            return data;
        } catch (error) {
            console.error("Error fetching free slots:", error);
            return undefined;
        }
    };
}
