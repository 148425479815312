import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import ColoredIcon from "../ColoredIcon";
import WalletIcon from "../../img/Wallet.svg";
import ClockIcon from "../../img/Clock.svg";
import Booking, { BookingState } from "../../api/Booking";
import Service from "../../api/Service";
import DateTimePicker from "../DateTimePicker";
import ConfirmButton from "../ConfirmButton";
import { useCookies } from "react-cookie";
import Brand from "../../api/Brand";
import { parseWithOffset } from "../../utils/dayjsUtils";
import { useAddress } from "../../contexts/AddressContext";

interface BookingSectionProps {
    brand: Brand;
    title: string;
    bookings: Booking[];
    type: "Waitlist" | "Active" | "History";
    reloadBookings: () => Promise<void>;
}

interface BookingItemProps {
    brand: Brand;
    booking: Booking;
    reloadBookings: () => Promise<void>;
    type: "Waitlist" | "Active" | "History";
}

const Bookings = () => {
    const address = useAddress();

    const [cookies] = useCookies(["client_id", "client_location_id"]);
    const { client_id, client_location_id } = cookies;

    const [brand, setBrand] = useState<Brand | undefined>();

    const [waitlist, setWaitlist] = useState<Booking[]>([]);
    const [activeBookings, setActiveBookings] = useState<Booking[]>([]);
    const [historyBookings, setHistoryBookings] = useState<Booking[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Brand.info(address).then(setBrand).catch(console.error);
    }, [address]);

    // Fetch all bookings for each section
    const reloadAllBookings = useCallback(async () => {
        setLoading(true);
        try {
            const waitlist = await Booking.listUser(
                client_id,
                address,
                client_location_id,
                "waiting"
            );
            const active = await Booking.listUser(
                client_id,
                address,
                client_location_id,
                "active"
            );
            const history = await Booking.listUser(
                client_id,
                address,
                client_location_id,
                "history"
            );

            setWaitlist(waitlist || []);
            setActiveBookings(active || []);
            setHistoryBookings(history || []);
        } finally {
            setLoading(false);
        }
    }, [client_id, client_location_id, address]);

    useEffect(() => {
        reloadAllBookings();
    }, [reloadAllBookings]);

    if (loading) return <CircularProgress></CircularProgress>;

    return (
        <Stack
            direction="column"
            width="100%"
            alignItems="center"
            spacing={3}
            overflow="scroll"
            paddingBottom={20}
        >
            {waitlist.length > 0 && (
                <BookingSection
                    brand={brand!}
                    title="Waitlist"
                    bookings={waitlist}
                    type="Waitlist"
                    reloadBookings={reloadAllBookings}
                />
            )}
            {activeBookings.length > 0 && (
                <BookingSection
                    brand={brand!}
                    title="Active bookings"
                    bookings={activeBookings}
                    type="Active"
                    reloadBookings={reloadAllBookings}
                />
            )}
            {historyBookings.length > 0 && (
                <BookingSection
                    brand={brand!}
                    title="Client history"
                    bookings={historyBookings}
                    type="History"
                    reloadBookings={reloadAllBookings}
                />
            )}
        </Stack>
    );
};

const BookingSection = (props: BookingSectionProps) => {
    const { brand, title, bookings, type, reloadBookings } = props;

    return (
        <>
            <Typography
                fontFamily={"Inter"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize="14px"
                lineHeight="18.2px"
            >
                {title}
            </Typography>
            {bookings.map((booking, index) => (
                <BookingItem
                    key={index}
                    brand={brand}
                    booking={booking}
                    type={type}
                    reloadBookings={reloadBookings}
                />
            ))}
        </>
    );
};

const BookingItem = (props: BookingItemProps) => {
    const theme = useTheme();

    const address = useAddress();

    const [cookies] = useCookies(["client_id", "client_location_id"]);
    const { client_id, client_location_id } = cookies;

    const { brand, booking, reloadBookings, type } = props;

    const bg = type === "Active" ? theme.palette.primary.main : "white";
    const fg = type === "Active" ? "white" : "#1A1A1A";

    const [service, setService] = useState<Service | undefined>();
    const [showReschedule, setShowReschedule] = useState(false);
    const [showCancel, setShowCancel] = useState(false);

    useEffect(() => {
        Service.info(address, booking.service)
            .then(setService)
            .catch(console.error);
        if (!booking.start) return;
        const now = dayjs();
        setShowReschedule(
            brand !== undefined &&
                brand.reschedule &&
                parseWithOffset(booking.start).diff(now, "days") >=
                    brand.appointmentReschedule
        );
        setShowCancel(
            brand !== undefined &&
                brand.cancellation &&
                parseWithOffset(booking.start).diff(now, "days") >=
                    brand.appointmentCancellation
        );
    }, [address, brand, booking]);

    const cancelBooking = useCallback(
        async (close: () => void) => {
            if (!client_id || !address || !client_location_id) return;
            await Booking.updateState(
                client_id,
                address,
                client_location_id,
                booking.key,
                BookingState.Cancel
            );
            reloadBookings();
            close();
        },
        [client_id, address, client_location_id, booking, reloadBookings]
    );

    const handleScheduling = useCallback(
        async (dateTime: Dayjs, close: () => void, action: string) => {
            const endDate = dateTime
                .add(service?.duration || 0, "minute")
                .toDate();
            if (action === "scheduleAgain") {
                await Booking.register(
                    client_id,
                    address,
                    client_location_id,
                    booking.service,
                    service!.duration,
                    service!.price,
                    dateTime.toDate(),
                    endDate
                );
            } else {
                await Booking.update(
                    client_id,
                    address,
                    client_location_id,
                    booking.key,
                    dateTime.toDate()
                );
            }
            reloadBookings();
            close();
        },
        [
            client_id,
            address,
            client_location_id,
            booking,
            service,
            reloadBookings,
        ]
    );

    if (!service) return <CircularProgress />;

    return (
        <Stack
            direction="column"
            width="85%"
            padding={2}
            spacing={1}
            bgcolor={bg}
            borderRadius="14px"
            border={`1px solid ${theme.palette.primary.main}`}
            color={fg}
            marginY={1}
        >
            {/* Header with Date and Conditional Canceled Status */}
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
            >
                <Typography variant="body2" fontWeight={400}>
                    {type === "Waitlist"
                        ? "As soon as possible"
                        : parseWithOffset(booking.start).format(
                              type === "Active" ? "MMMM D, HH:mm" : "DD.MM.YYYY"
                          )}
                </Typography>
                {booking.state === "cancel" && (
                    <Typography variant="body2" fontWeight={400} color="error">
                        Cancelled
                    </Typography>
                )}
            </Stack>
            {/* Service Info */}
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
            >
                <Stack direction="column" spacing={2}>
                    <Typography
                        fontFamily={"Inter"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={500}
                    >
                        {service.name}
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <ColoredIcon icon={WalletIcon} size="20px" color={fg} />
                        <Typography variant="body2" fontWeight={700}>
                            €{service.price}
                        </Typography>
                        <ColoredIcon
                            icon={ClockIcon}
                            size="20px"
                            color={fg}
                            style={{ opacity: 0.6 }}
                        />
                        <Typography variant="body2" fontWeight={700}>
                            {Math.floor(service.duration / 60)}h{" "}
                            {service.duration % 60}m
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction="column" spacing={1} alignItems="flex-end">
                    {/* Actions */}
                    {type === "Waitlist" ? (
                        <>
                            <ConfirmButton
                                button={{
                                    variant: "small",
                                    color: "transparent",
                                }}
                                onConfirm={cancelBooking}
                            >
                                Delete from Waitlist
                            </ConfirmButton>
                        </>
                    ) : type === "Active" ? (
                        <>
                            {showReschedule && (
                                <DateTimePicker
                                    address={address}
                                    duration={service.duration}
                                    button={{
                                        variant: "small",
                                        color: "secondary",
                                    }}
                                    onSave={(dateTime, close) =>
                                        handleScheduling(
                                            dateTime,
                                            close,
                                            "reschedule"
                                        )
                                    }
                                >
                                    Reschedule
                                </DateTimePicker>
                            )}
                            {showCancel && (
                                <ConfirmButton
                                    button={{
                                        variant: "small",
                                        color: "secondary",
                                    }}
                                    onConfirm={cancelBooking}
                                >
                                    Cancel reservation
                                </ConfirmButton>
                            )}
                        </>
                    ) : (
                        <>
                            <DateTimePicker
                                address={address}
                                duration={service.duration}
                                button={{
                                    variant: "small",
                                    color: "transparent",
                                }}
                                onSave={(dateTime, close) =>
                                    handleScheduling(
                                        dateTime,
                                        close,
                                        "scheduleAgain"
                                    )
                                }
                            >
                                Schedule Again
                            </DateTimePicker>
                        </>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Bookings;
