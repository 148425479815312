import React, { useEffect } from "react";
import { CircularProgress, Stack } from "@mui/material";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useAddress } from "../contexts/AddressContext";

export const loader = async (request: any) => {
    const client_id = await request.params.client_id;
    return { client_id };
};

const Redirect = () => {
    const navigate = useNavigate();
    const address = useAddress();
    const client_id = (useLoaderData() as any).client_id as string;

    const [, setCookie] = useCookies(["client_id"]);

    useEffect(() => {
        setCookie("client_id", client_id, { path: "/" });
        navigate(`/`);
    }, [client_id, setCookie, address, navigate]);

    return (
        <Stack
            height="100vh"
            width="100%"
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress />
        </Stack>
    );
};

export default Redirect;
