import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";

const environment = getEnvironment();

export default class Category {
    key: string;
    name: string;

    constructor(key: string, name: string) {
        this.key = key;
        this.name = name;
    }

    public static readonly list = async (brand: string, duration?: number) =>
        (await axios
            .get(environment.api_url + "/" + brand + "/category/list", {
                params: duration ? { duration } : undefined,
            })
            .then((res) => (res.status === 200 ? res.data : undefined))
            .catch(console.error)) as Category[] | undefined;
}
