const check = (num: number) => (num < 0 ? 0 : num > 255 ? 255 : num);

export default class Color {
    private _r: number;
    private _g: number;
    private _b: number;

    constructor(r: number, g: number, b: number) {
        this._r = check(r);
        this._g = check(g);
        this._b = check(b);
    }

    public get r(): number {
        return this._r;
    }

    public get g(): number {
        return this._g;
    }

    public get b(): number {
        return this._b;
    }

    public set r(val: number) {
        this._r = check(val);
    }

    public set g(val: number) {
        this._g = check(val);
    }

    public set b(val: number) {
        this._b = check(val);
    }

    public toHex() {
        const f = (x: number) => (x < 16 ? "0" : "") + x.toString(16);
        return "#" + f(this._r) + f(this._g) + f(this._b);
    }

    public toJSON(): string {
        return JSON.stringify({ r: this._r, g: this._g, b: this._b });
    }

    public static fromJSON(json: string): Color {
        const data = JSON.parse(json);
        return new Color(data.r, data.g, data.b);
    }
}
