import React, { useCallback, useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Button from "./Button";
import PasswordField from "./PasswordField";
import { useCookies } from "react-cookie";
import Bg from "./Bg";
import User from "../api/User";
import { useLoaderData, useNavigate } from "react-router-dom";
import Brand from "../api/Brand";
import ColoredIcon from "./ColoredIcon";
import DefaultIcon from "../img/default_icon.svg";
import Color from "../modules/Color";
import { useAddress } from "../contexts/AddressContext";

export const loader = async (request: any) => {
    const rkey = await request.params.rkey;
    return { rkey };
};

const CreatePassword = () => {
    const navigate = useNavigate();
    const address = useAddress();
    const rkey = (useLoaderData() as any).rkey as string;

    const [, setCookie] = useCookies(["client_id"]);

    const [brand, setBrand] = useState<Brand | undefined>();
    const [logo, setLogo] = useState<string | undefined>();

    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Brand.info(address)
            .then((res) => {
                setBrand(res);
                setLogo(res?.logo);
            })
            .catch(console.error);
    }, [address]);

    const changePassword = useCallback(() => {
        if (password === "") {
            alert("Fill all the fields.");
            return;
        }
        setLoading(true);
        User.rkeySignIn(address, rkey, password)
            .then((res) => {
                setCookie("client_id", res, { path: "/" });
                navigate(`/`);
            })
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [setCookie, address, rkey, password, navigate]);

    return (
        <Stack height="100vh" width="100%" alignItems="center">
            <Bg />
            <Stack
                width="80%"
                height="100%"
                alignItems="center"
                justifyContent="center"
                spacing={8}
                marginTop={8}
            >
                <Stack spacing={1}>
                    {brand && (
                        <Box
                            width="96px"
                            height="96px"
                            borderRadius="22px"
                            bgcolor={new Color(
                                brand.colorRed,
                                brand.colorGreen,
                                brand.colorBlue
                            ).toHex()}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {logo ? (
                                <Box
                                    width="100%"
                                    height="100%"
                                    borderRadius="22px"
                                    sx={{
                                        backgroundImage: `url(${logo})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                />
                            ) : (
                                <ColoredIcon
                                    icon={DefaultIcon}
                                    size={64}
                                    color="white"
                                />
                            )}
                        </Box>
                    )}
                    <Typography
                        fontWeight={500}
                        fontSize="19px"
                        lineHeight="21.28px"
                        textAlign="center"
                    >
                        {brand?.name}
                    </Typography>
                </Stack>
                <Stack spacing={1} width="100%">
                    <PasswordField
                        variant="contained"
                        fullWidth
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                </Stack>
                <Button
                    variant="big"
                    disabled={loading}
                    onClick={() => changePassword()}
                    color="black"
                    fullWidth
                >
                    Confirm
                </Button>
            </Stack>
        </Stack>
    );
};

export default CreatePassword;
