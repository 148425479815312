import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";

const env = getEnvironment();

interface PushSubscriptionPayload {
    user_id: string;
    subscription: PushSubscription;
}

export const subscribeToPushNotifications = async (
    payload: PushSubscriptionPayload
): Promise<void> => {
    try {
        const response = await axios.post(
            `${env.server_address}/user/subscribe`,
            payload,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (response.status !== 200) {
            throw new Error(`Failed to subscribe: ${response.statusText}`);
        }

        console.log("Push subscription successfully saved on the server.");
    } catch (error) {
        console.error("Error in subscribeToPushNotifications:", error);
    }
};
