import React, { useEffect, useState } from "react";
import {
    Avatar,
    List,
    ListItemButton,
    ListItemText,
    ListItemAvatar,
} from "@mui/material";
import Brand from "../modules/Brand";
import { useCookies } from "react-cookie";

const BrandsList = () => {
    const [brands, setBrands] = useState<Brand[]>([]);

    const [, , removeCookie] = useCookies([
        "client_id",
        "client_location_id",
        "app_theme",
    ]);

    useEffect(() => {
        removeCookie("client_id", { domain: "", path: "/" });
        removeCookie("client_location_id", { domain: "", path: "/" });
        removeCookie("app_theme", { domain: "", path: "/" });
        Brand.getAll()
            .then((res) => {
                setBrands(
                    res.data.map(
                        (x: any) =>
                            new Brand(
                                x.name,
                                x.address,
                                x.color_red,
                                x.color_green,
                                x.color_blue,
                                x.logo
                            )
                    )
                );
            })
            .catch((err) => console.error(`Brands loading error: ${err}`));
    }, [removeCookie]);

    const onClick = (address: string) => {
        // Extract the current domain from the URL
        const currentDomain = window.location.hostname
            .split(".")
            .slice(1)
            .join(".");

        const protocol = currentDomain === "localhost" ? "http" : "https";

        // Redirect to the chosen brand subdomain
        window.location.href = `${protocol}://${address}.${
            currentDomain === "localhost"
                ? currentDomain + ":3001"
                : currentDomain
        }`;
    };

    return (
        <List>
            {brands.map((brand) => (
                <ListItemButton
                    key={brand.address}
                    onClick={() => onClick(brand.address)}
                >
                    <ListItemAvatar>
                        <Avatar
                            sx={{
                                bgcolor: `rgb(${brand.color_red}, ${brand.color_green}, ${brand.color_blue})`,
                            }}
                            src={brand.logo ?? undefined}
                        >
                            {(brand.name ?? "?")[0]}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText>{brand.name}</ListItemText>
                </ListItemButton>
            ))}
        </List>
    );
};

export default BrandsList;
