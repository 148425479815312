import { SxProps, Theme } from "@mui/material";
import React, { useState } from "react";
import TextField from "./TextField";
import Show from "../img/Show.svg";

interface Props {
    placeholder?: string;
    value?: string;
    variant?: "contained" | "outlined";
    width?: number | string;
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onKeyDown?: React.KeyboardEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    >;
    error?: boolean;
    sx?: SxProps<Theme>;
    fullWidth?: boolean;
}

const PasswordTextField = (props: Props) => {
    const {
        placeholder,
        value,
        variant,
        onChange,
        onFocus,
        onBlur,
        width,
        onKeyDown,
        error,
        sx,
        fullWidth,
    } = props;

    const [show, setShow] = useState(false);

    return (
        <TextField
            placeholder={placeholder}
            value={value}
            variant={variant}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            width={width}
            onKeyDown={onKeyDown}
            error={error}
            sx={sx}
            fullWidth={fullWidth}
            icon={Show}
            onClick={() => setShow(!show)}
            type={show ? "text" : "password"}
        />
    );
};

export default PasswordTextField;
