import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";

const environment = getEnvironment();

interface ServiceRaw {
    key: string;
    category: string;
    name: string;
    duration: number;
    price: number;
    description: string;
    image?: string;
}

export default class Service {
    key: string;
    category: string;
    name: string;
    duration: number;
    price: number;
    image?: string;
    description: string;

    constructor(data: ServiceRaw) {
        this.key = data.key;
        this.category = data.category;
        this.name = data.name;
        this.duration = data.duration;
        this.price = data.price;
        this.description = data.description;
        this.image = data.image;
    }

    public static readonly list = async (
        brand: string,
        category: string,
        duration?: number
    ) =>
        (
            (await axios
                .get(
                    environment.api_url +
                        "/" +
                        brand +
                        "/category/" +
                        category +
                        "/list",
                    {
                        params: duration ? { duration } : undefined,
                    }
                )
                .then((res) => (res.status === 200 ? res.data : undefined))
                .catch(console.error)) as ServiceRaw[] | undefined
        )?.map((x) => new Service(x));

    public static readonly info = async (brand: string, service: string) => {
        const data = (await axios
            .get(environment.api_url + "/" + brand + "/service/" + service)
            .then((res) => (res.status === 200 ? res.data : undefined))
            .catch(console.error)) as ServiceRaw | undefined;
        if (data) return new Service(data);
        return undefined;
    };
}
