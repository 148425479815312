import React, { useEffect, useState } from "react";
import Brand from "../api/Brand";
import { Stack, Typography } from "@mui/material";
import BgImage from "./BgImage";
import Header from "./Header";
import Footer from "./Footer";
import Button from "./Button";
import Bg from "./Bg";
import { useCookies } from "react-cookie";
import Location from "../api/Location";
import { useAddress } from "../contexts/AddressContext";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();
    const address = useAddress();
    const [, setCookie] = useCookies(["app_theme", "client_location_id"]);

    const [bgImage, setBgImage] = useState<string | undefined>();
    const [description, setDescription] = useState<string>("");

    useEffect(() => {
        Brand.info(address).then((res) => {
            if (!res) return;
            setDescription(res.description);
        });
    });

    useEffect(() => {
        Location.list(address).then((res) => {
            if (!res || res.length === 0) return;
            setBgImage(res[0].image);
        });
    }, [address, setCookie]);

    return (
        <Stack
            direction="column"
            width="100%"
            height="100vh"
            justifyContent="center"
            alignItems="center"
        >
            {bgImage ? <BgImage image={bgImage} /> : <Bg />}
            <Header />
            <Stack
                width="100%"
                height="100%"
                justifyContent="flex-end"
                alignItems="center"
            >
                <Stack
                    width="85%"
                    marginY={14}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                    spacing={2}
                    borderRadius="20px"
                    sx={{
                        background: "rgba(255, 255, 255, 0.8)",
                    }}
                >
                    {description && (
                        <Stack width="90%">
                            <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="18.2px"
                                width="100%"
                                sx={{
                                    wordBreak: "break-word",
                                }}
                            >
                                {description}
                            </Typography>
                        </Stack>
                    )}
                    <Stack width="100%">
                        <Button
                            variant="large"
                            fullWidth
                            onClick={() => navigate(`/services`)}
                        >
                            Online Booking
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
            <Footer tab="home" />
        </Stack>
    );
};

export default Home;
