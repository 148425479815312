import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";

const environment = getEnvironment();

interface ServiceGalleryRaw {
    image: string;
}

export default class ServiceGallery {
    image: string;

    constructor(data: ServiceGalleryRaw) {
        this.image = data.image;
    }

    public static readonly list = async (brand: string, service: string) =>
        (
            (await axios
                .get(
                    environment.api_url +
                        "/" +
                        brand +
                        "/service/" +
                        service +
                        "/list"
                )
                .then((res) => (res.status === 200 ? res.data : undefined))
                .catch(console.error)) as ServiceGalleryRaw[] | undefined
        )?.map((x) => new ServiceGallery(x));
}
