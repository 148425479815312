import React, { useCallback, useEffect, useState } from "react";
import Header from "../Header";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import Button from "../Button";
import ColoredIcon from "../ColoredIcon";
import Color from "../../modules/Color";
import DefaultIcon from "../../img/default_icon.svg";
//import GoogleIcon from "../../img/Google.svg";
import User from "../../api/User";
import Brand from "../../api/Brand";
//import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import TextField from "../TextField";
import PasswordField from "../PasswordField";
import { useCookies } from "react-cookie";
import Bg from "../Bg";
import TabHeader from "../TabHeader";
import { useNavigate } from "react-router-dom";
import { useAddress } from "../../contexts/AddressContext";

interface Props {
    type: "profile" | "chat" | "services";
}

const SignUp = (props: Props) => {
    const navigate = useNavigate();
    const address = useAddress();

    const { type } = props;

    //const [token, setToken] = useState<TokenResponse | undefined>();
    const [brand, setBrand] = useState<Brand | undefined>();
    const [logo, setLogo] = useState<string | undefined>();

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [stdRegister, setStdRegister] = useState(false);
    const [loading, setLoading] = useState(false);

    const [, setCookie] = useCookies(["client_id"]);

    useEffect(() => {
        Brand.info(address)
            .then((res) => {
                setBrand(res);
                setLogo(res?.logo);
            })
            .catch(console.error);
    }, [address]);

    /*
    const onGoogleLoginError = () => console.error("Google login error.");
    const googleLogin = useGoogleLogin({
        onSuccess: (res) => setToken(res),
        onError: () => onGoogleLoginError(),
    });

    useEffect(() => {
        if (!token) return;
        setLoading(true);
        User.googleSignUp(address, token.access_token)
            .then((uuid) => {
                if (!uuid) return;
                setCookie("client_id", uuid, {
                    domain: "",
                    path: "/",
                });
                if (type === "services") {
                    const service_id =
                        sessionStorage.getItem("selected_service");
                    if (service_id) {
                        navigate(`/services/${service_id}/confirmation`);
                    } else {
                        navigate(`/services`);
                    }
                    return;
                }
                navigate(`/${type}`);
            })
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [token, address, setCookie, type, navigate]);
    */

    const standardSignUp = useCallback(() => {
        if (
            name.trim() === "" ||
            surname.trim() === "" ||
            email.trim() === "" ||
            password === ""
        ) {
            alert("Fill all the fields.");
            return;
        }
        setLoading(true);
        User.signUp(
            address,
            `${name.trim()} ${surname.trim()}`,
            email.trim(),
            password
        )
            .then((uuid) => {
                if (!uuid) {
                    alert("This email is already used.");
                    return;
                }
                setCookie("client_id", uuid, {
                    domain: "",
                    path: "/",
                });
                if (type === "services") {
                    const service_id =
                        sessionStorage.getItem("selected_service");
                    if (service_id) {
                        navigate(`/services/${service_id}/confirmation`);
                    } else {
                        navigate(`/services`);
                    }
                    return;
                }
                navigate(`/${type}`);
            })
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [address, email, name, password, setCookie, surname, type, navigate]);

    if (!brand) return <CircularProgress />;
    if (!stdRegister)
        return (
            <Stack
                className="force-dynamic-translation"
                height="100vh"
                width="100%"
                alignItems="center"
            >
                <Bg />
                <Header />
                <TabHeader name="Sign Up" onClose={() => navigate(-1)} />
                <Stack
                    width="80%"
                    alignItems="center"
                    spacing={8}
                    marginTop={10}
                >
                    <Stack spacing={1}>
                        <Box
                            width="96px"
                            height="96px"
                            borderRadius="22px"
                            bgcolor={new Color(
                                brand.colorRed,
                                brand.colorGreen,
                                brand.colorBlue
                            ).toHex()}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {logo ? (
                                <Box
                                    width="100%"
                                    height="100%"
                                    borderRadius="22px"
                                    sx={{
                                        backgroundImage: `url(${logo})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                />
                            ) : (
                                <ColoredIcon
                                    icon={DefaultIcon}
                                    size={64}
                                    color="white"
                                />
                            )}
                        </Box>
                        <Typography
                            fontWeight={500}
                            fontSize="19px"
                            lineHeight="21.28px"
                            textAlign="center"
                        >
                            {brand?.name}
                        </Typography>
                    </Stack>
                    {/* 
                    <Stack spacing={1}>
                        <Typography
                            fontWeight={400}
                            fontSize="14px"
                            lineHeight="18.2px"
                            textAlign="center"
                            sx={{ opacity: "80%" }}
                        >
                            Register or sign up with Google
                        </Typography>
                        <Button
                            variant="big"
                            icon={GoogleIcon}
                            disabled={loading}
                            onClick={() => googleLogin()}
                            fullWidth
                        >
                            Sign up with Google
                        </Button>
                    </Stack>
                    */}
                    <Stack spacing={1}>
                        <Typography
                            fontWeight={400}
                            fontSize="14px"
                            lineHeight="18.2px"
                            textAlign="center"
                            sx={{ opacity: "80%" }}
                        >
                            If you do not have a Google account, please proceed
                            with:
                        </Typography>
                        <Button
                            variant="big"
                            color="outlined"
                            disabled={loading}
                            onClick={() => setStdRegister(true)}
                            fullWidth
                        >
                            Standard registration
                        </Button>
                    </Stack>
                </Stack>
                <Stack
                    height="100%"
                    width="80%"
                    justifyContent="flex-end"
                    alignItems="center"
                    marginBottom={6}
                    spacing={2}
                >
                    <Typography
                        fontWeight={400}
                        fontSize="10px"
                        lineHeight="15px"
                        textAlign="center"
                        sx={{ opacity: "50%" }}
                    >
                        By using our app, you agree to our terms and accept that
                        we are not responsible for any issues arising from its
                        use. For more details, please refer to the{" "}
                        <a href="#" style={{ color: "black" }}>
                            full disclaimer
                        </a>
                        .
                    </Typography>
                </Stack>
            </Stack>
        );
    else
        return (
            <Stack
                className="force-dynamic-translation"
                height="100vh"
                width="100%"
                alignItems="center"
            >
                <Bg />
                <Header />
                <TabHeader
                    name="Sign Up"
                    onClose={() => setStdRegister(false)}
                />
                <Stack
                    width="80%"
                    alignItems="center"
                    spacing={8}
                    marginTop={8}
                >
                    <Stack spacing={1}>
                        <Box
                            width="96px"
                            height="96px"
                            borderRadius="22px"
                            bgcolor={new Color(
                                brand.colorRed,
                                brand.colorGreen,
                                brand.colorBlue
                            ).toHex()}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {logo ? (
                                <Box
                                    width="100%"
                                    height="100%"
                                    borderRadius="22px"
                                    sx={{
                                        backgroundImage: `url(${logo})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                />
                            ) : (
                                <ColoredIcon
                                    icon={DefaultIcon}
                                    size={64}
                                    color="white"
                                />
                            )}
                        </Box>
                        <Typography
                            fontWeight={500}
                            fontSize="19px"
                            lineHeight="21.28px"
                            textAlign="center"
                        >
                            {brand?.name}
                        </Typography>
                    </Stack>
                    <Stack spacing={1} width="100%">
                        <TextField
                            variant="contained"
                            fullWidth
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.currentTarget.value)}
                        />
                        <TextField
                            variant="contained"
                            fullWidth
                            placeholder="Surname"
                            value={surname}
                            onChange={(e) => setSurname(e.currentTarget.value)}
                        />
                        <TextField
                            variant="contained"
                            fullWidth
                            placeholder="email@gmail.com"
                            value={email}
                            onChange={(e) =>
                                setEmail(
                                    e.currentTarget.value.toLocaleLowerCase()
                                )
                            }
                        />
                        <PasswordField
                            variant="contained"
                            fullWidth
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                        />
                    </Stack>
                    <Button
                        variant="big"
                        disabled={loading}
                        onClick={() => standardSignUp()}
                        color="black"
                        fullWidth
                    >
                        Register
                    </Button>
                </Stack>
                <Stack
                    height="100%"
                    width="80%"
                    justifyContent="flex-end"
                    alignItems="center"
                    marginBottom={6}
                    spacing={2}
                >
                    <Typography
                        fontWeight={400}
                        fontSize="10px"
                        lineHeight="15px"
                        textAlign="center"
                        sx={{ opacity: "50%" }}
                    >
                        By using our app, you agree to our terms and accept that
                        we are not responsible for any issues arising from its
                        use. For more details, please refer to the{" "}
                        <a href="#" style={{ color: "black" }}>
                            full disclaimer
                        </a>
                        .
                    </Typography>
                </Stack>
            </Stack>
        );
};

export default SignUp;
