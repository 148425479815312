import { Stack, Typography, useTheme } from "@mui/material";
import ColoredIcon from "../ColoredIcon";
import Checkmark from "../../img/Ok_flag.svg";
import Button from "../Button";
import Bg from "../Bg";
import React from "react";
import Header from "../Header";
import { useNavigate } from "react-router-dom";

const Done = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    const onInstall = () => {
        navigate(`/services`);
    };

    const onLater = () => {
        navigate(`/services`);
    };

    return (
        <Stack
            direction="column"
            width="100%"
            height="100vh"
            justifyContent="flex-start"
            alignItems="center"
        >
            <Bg />
            <Header />
            <Stack width="80%" alignItems="center" spacing={8} marginTop={10}>
                <Typography
                    fontWeight={700}
                    fontSize="24px"
                    lineHeight="28.8px"
                    textAlign="center"
                    color="primary"
                >
                    Congratulations! You have successfully booked your
                    appointment
                </Typography>
                <ColoredIcon
                    icon={Checkmark}
                    color={theme.palette.primary.main}
                    size="96px"
                    style={{
                        padding: "15px",
                    }}
                />
                <Typography
                    fontWeight={300}
                    fontSize="14px"
                    lineHeight="18.2px"
                    textAlign="center"
                    sx={{ opacity: "80%" }}
                >
                    To receive notifications, correspond with the master, and be
                    able to manage your appointment, please install this
                    application.
                </Typography>
                <Stack spacing={2} width="100%">
                    <Button onClick={onInstall}>Install App</Button>
                    <Button onClick={onLater}>Later</Button>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Done;
