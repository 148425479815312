import React from "react";
import { Box } from "@mui/material";

interface Props {
    image: string;
    full?: boolean;
}

const Bg = (props: Props) => {
    const { image, full } = props;

    return (
        <>
            <Box
                position="absolute"
                width="100vw"
                height="100vh"
                top={0}
                left={0}
                zIndex={-12}
                sx={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            />
            {full && (
                <Box
                    position="absolute"
                    width="100vw"
                    height="100vh"
                    top={0}
                    left={0}
                    zIndex={-12}
                    sx={{
                        background: "white",
                        opacity: "75%",
                    }}
                />
            )}
        </>
    );
};

export default Bg;
