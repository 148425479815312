import React, { useCallback, useEffect, useState } from "react";
import { Box, ButtonBase, Stack, Typography, useTheme } from "@mui/material";
import ColoredIcon from "./ColoredIcon";
import MenuIcon from "../img/Sandvich_Cross.svg";
import Logo from "../img/TextLogo.svg";
import { useCookies } from "react-cookie";
import { googleLogout } from "@react-oauth/google";
import User from "../api/User";
import { useAddress } from "../contexts/AddressContext";
import { useNavigate } from "react-router-dom";

interface Props {
    onClose: () => void;
}

const Menu = (props: Props) => {
    const appAddress = useAddress();
    const { onClose } = props;

    const [cookies, , removeCookie] = useCookies(["client_id"]);
    const { client_id } = cookies;

    const [local, setLocal] = useState(false);

    const navigate = useNavigate();

    const navigateTo = useCallback(
        (address: string) => {
            navigate(`/${address}`);
        },
        [navigate]
    );

    useEffect(() => {
        if (!client_id) return;
        User.info(client_id, appAddress)
            .then((res) => setLocal(res?.local ?? false))
            .catch(console.error);
    }, [client_id, appAddress]);

    const onAboutClick = () => navigateTo("menu/about");
    const onContactsClick = () => navigateTo("menu/contacts");
    const onChangePasswordClick = () => navigateTo("menu/password");
    const onLogOut = useCallback(async () => {
        if (client_id) {
            await User.signOut(client_id, appAddress);
            removeCookie("client_id", { domain: "", path: "/" });
            googleLogout();
        }
        navigate("");
    }, [client_id, appAddress, removeCookie, navigate]);

    return (
        <Stack
            direction="column"
            width="65%"
            height="100vh"
            alignItems="flex-end"
            justifyContent="flex-start"
            spacing={2}
            bgcolor="white"
            position="absolute"
            right="0"
            top="0"
        >
            <Stack
                direction="row"
                height="48px"
                minHeight="48px"
                maxHeight="48px"
                alignItems="center"
                paddingRight={2.5}
            >
                <ButtonBase
                    onClick={onClose}
                    sx={{ width: "32px", height: "32px", borderRadius: "32px" }}
                >
                    <ColoredIcon size="100%" icon={MenuIcon} />
                </ButtonBase>
            </Stack>
            <TabButton onClick={onAboutClick}>About</TabButton>
            <TabButton onClick={onContactsClick}>Contacts</TabButton>
            {local && (
                <TabButton onClick={onChangePasswordClick}>
                    Change password
                </TabButton>
            )}
            {cookies.client_id && (
                <TabButton onClick={onLogOut}>Log Out</TabButton>
            )}
            <Stack
                direction="column"
                height="100%"
                width="90%"
                justifyContent="flex-end"
            >
                <img
                    src={Logo}
                    alt="logo"
                    style={{
                        width: "147px",
                        height: "34px",
                        paddingBottom: 30,
                    }}
                />
            </Stack>
        </Stack>
    );
};

const TabButton = (props: { children?: string; onClick?: () => void }) => {
    const theme = useTheme();

    const { children, onClick } = props;

    return (
        <ButtonBase
            onClick={onClick}
            sx={{
                width: "95%",
                textAlign: "start",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: 1,
            }}
        >
            <Stack width="100%" spacing={1}>
                <Typography
                    className="force-dynamic-translation"
                    fontWeight={400}
                    fontSize="16px"
                    lineHeight="24px"
                    paddingX={1}
                >
                    {children}
                </Typography>
                <Box
                    height="1px"
                    width="100%"
                    bgcolor={theme.palette.primary.main}
                />
            </Stack>
        </ButtonBase>
    );
};

export default Menu;
