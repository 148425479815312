import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";
import { Dayjs } from "dayjs";

const environment = getEnvironment();

export enum NotificationType {
    Booking = "booking",
    Chat = "chat",
}

export default class Notification {
    key: string;
    type: NotificationType;
    object: string;
    message: string;
    created: Dayjs;
    viewed?: Dayjs;

    constructor(
        key: string,
        type: NotificationType,
        object: string,
        message: string,
        created: Dayjs,
        viewed?: Dayjs
    ) {
        this.key = key;
        this.type = type;
        this.object = object;
        this.message = message;
        this.created = created;
        this.viewed = viewed;
    }

    public static readonly list = async (uuid: string, brand: string) =>
        (await axios
            .get(environment.api_url + "/" + brand + "/notifications/list", {
                headers: {
                    "User-Token": uuid,
                },
            })
            .then((res) => (res.status === 200 ? res.data : undefined))
            .catch(console.error)) as Notification[] | undefined;

    public static readonly viewed = async (
        uuid: string,
        brand: string,
        notification: string
    ) =>
        axios.put(
            environment.api_url + "/" + brand + "/notification/" + notification,
            undefined,
            {
                headers: {
                    "User-Token": uuid,
                },
                timeout: 5000,
            }
        );

    public static readonly info = async (
        uuid: string,
        brand: string,
        notificationKey: string
    ) =>
        (await axios
            .get(
                environment.api_url +
                    "/" +
                    brand +
                    "/notification/" +
                    notificationKey,
                {
                    headers: {
                        "User-Token": uuid,
                    },
                }
            )
            .then((res) => (res.status === 200 ? res.data : undefined))
            .catch(console.error)) as Notification | undefined;
}
