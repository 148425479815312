import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { getEnvironment } from "../utils/getEnvironment";
import logo from "../img/Logo.svg";
import textLogo from "../img/TextLogo.svg";

const BrandNotFound: React.FC = () => {
    const environment = getEnvironment();

    return (
        <Stack
            alignItems="center"
            justifyContent="space-between"
            height="90vh"
            spacing={4}
            sx={{
                bgcolor: "background.default",
                textAlign: "center",
                padding: 4,
            }}
        >
            <Stack>
                <img src={logo} alt="" />
                <img src={textLogo} alt="" />
            </Stack>
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                gap={2}
                sx={{ mt: 4 }}
            >
                {/* Heading */}
                <Typography
                    fontSize="24px"
                    fontWeight={700}
                    color="text.primary"
                    sx={{ mb: 1 }}
                >
                    Brand Not Found
                </Typography>

                {/* Subtitle */}
                <Typography
                    fontSize="18px"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                >
                    It seems like the brand you're searching for doesn’t exist.
                    But don’t worry, you can create a new one and bring your
                    ideas to life!
                </Typography>
            </Box>
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                gap={2}
                sx={{ mt: 4 }}
            >
                {" "}
                {/* TEMPORARY TESTING Button */}
                <Button
                    fullWidth
                    sx={{
                        padding: "12px 24px",
                        borderRadius: "8px",
                        backgroundColor: "primary.main",
                        color: "white",
                        fontSize: "16px",
                        fontWeight: 600,
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: "primary.dark",
                        },
                    }}
                    onClick={() =>
                        (window.location.href = environment.brands_list!)
                    }
                >
                    All Brands TEST
                </Button>
                {/* Action Button */}
                <Button
                    fullWidth
                    sx={{
                        padding: "12px 24px",
                        borderRadius: "8px",
                        backgroundColor: "primary.main",
                        color: "white",
                        fontSize: "16px",
                        fontWeight: 600,
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: "primary.dark",
                        },
                    }}
                    onClick={() =>
                        (window.location.href = environment.starter_url)
                    }
                >
                    Create Your Brand
                </Button>
            </Box>
        </Stack>
    );
};

export default BrandNotFound;
