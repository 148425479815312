import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const parseWithOffset = (input: string | Dayjs): Dayjs => {
    // Step 1: Parse the input string in UTC
    const isoString = dayjs.isDayjs(input) ? input.toISOString() : input;
    const utcTime = dayjs.utc(isoString);

    // Step 2: Extract the offset part of the string using slice
    const offset = isoString.slice(-6); // Extracts "+02:00" or "-03:00"

    // Step 3: Add the parsed offset (in minutes) to the UTC time
    const adjustedTime = utcTime;

    // Attach the original offset as a property (for reference)
    return adjustedTime.utcOffset(offset);
};

export const normalizeToOffset = (
    date: Dayjs | undefined
): Dayjs | undefined => {
    const offset = "+00:00";
    return date
        ? parseWithOffset(date).utcOffset(offset, true).startOf("day")
        : undefined; // Keeps date within correct local offset
};
