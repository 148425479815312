import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    ButtonBase,
    CircularProgress,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Bg from "./Bg";
import TabHeader from "./TabHeader";
import Notification, { NotificationType } from "../api/Notification";
import { useCookies } from "react-cookie";
import { parseWithOffset } from "../utils/dayjsUtils";
import { useAddress } from "../contexts/AddressContext";

const NotificationDetails = () => {
    const [cookies] = useCookies(["client_id"]);
    const { client_id } = cookies;

    const address = useAddress();

    const { notificationKey } = useParams<{ notificationKey: string }>();
    const navigate = useNavigate();
    const theme = useTheme();

    const [notification, setNotification] = useState<
        Notification | undefined
    >();

    const onBack = useCallback(() => {
        if (window.history.length > 1) {
            navigate(-1);
        } else {
            navigate(`/${address}/notifications`);
        }
    }, [address, navigate]);

    useEffect(() => {
        if (!client_id || !address || !notificationKey) {
            navigate(`/${address}`);
            return;
        }

        Notification.info(client_id, address, notificationKey)
            .then((res) => setNotification(res))
            .catch(console.error);
        Notification.viewed(client_id, address, notificationKey);
    }, [client_id, address, notificationKey, navigate]);

    const handleAction = useCallback(() => {
        if (!notification) return;

        switch (notification.type) {
            case NotificationType.Booking:
                navigate(`/${address}/appointment/${notification.object}`);
                break;
            case NotificationType.Chat:
                navigate(`/chat`);
                break;
            default:
                console.error("Unknown notification type");
        }
    }, [notification, address, navigate]);

    if (!notification) {
        return (
            <Stack height="100vh" width="100%" alignItems="center">
                <Bg />
                <TabHeader name="Notification Details" onClose={onBack} />
                <Stack
                    width="100%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress color="primary" />
                </Stack>
            </Stack>
        );
    }

    const title =
        notification.type === NotificationType.Booking ? "Booking" : "Chat";

    return (
        <Stack
            height="100vh"
            width="100%"
            alignItems="center"
            justifyContent="flex-start"
            sx={{
                background: "linear-gradient(180deg, #F8F9FD 0%, #FFFFFF 100%)",
            }}
        >
            <Bg />
            <TabHeader name="Notification Details" onClose={onBack} />
            <Stack
                direction="column"
                width="92%"
                height="95%"
                alignItems="center"
                justifyContent="space-between" // Ensures the button is pushed to the bottom
                paddingX={2}
                paddingBottom={4} // Add padding for better reachability
                overflow="hidden" // Prevents overflow issues
                className="force-dynamic-translation"
                marginTop={4}
            >
                <Stack
                    direction="column"
                    width="100%"
                    alignItems="center"
                    overflow="auto"
                    spacing={3}
                >
                    <Box
                        borderRadius="20px"
                        boxShadow="0px 8px 24px rgba(0, 0, 0, 0.1)"
                        bgcolor="white"
                        padding={4}
                        sx={{
                            position: "relative",
                            overflow: "hidden",
                            "::after": {
                                content: "''",
                                position: "absolute",
                                top: 0,
                                right: 0,
                                width: "120px",
                                height: "120px",
                                background: "rgba(122, 146, 255, 0.1)",
                                borderRadius: "50%",
                                transform: "translate(50%, -50%)",
                            },
                        }}
                    >
                        <Typography
                            fontWeight={700}
                            fontSize="22px"
                            lineHeight="30px"
                            marginBottom={2}
                            color={theme.palette.primary.main}
                        >
                            {title}
                        </Typography>
                        <Typography
                            fontWeight={400}
                            fontSize="16px"
                            lineHeight="24px"
                            marginBottom={3}
                            color="#333333"
                        >
                            {notification.message}
                        </Typography>
                        <Typography
                            fontWeight={400}
                            fontSize="14px"
                            lineHeight="20px"
                            color="#999999"
                        >
                            {parseWithOffset(notification.created).format(
                                "dddd, MMMM D, YYYY HH:mm"
                            )}
                        </Typography>
                    </Box>
                </Stack>
                <ButtonBase
                    onClick={handleAction}
                    sx={{
                        width: "90%",
                        height: "50px",
                        borderRadius: "12px",
                        bgcolor: theme.palette.primary.main,
                        marginBottom: 3,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: 600,
                        fontSize: "16px",
                        textTransform: "none",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                        transition: "transform 0.2s, box-shadow 0.2s",
                        "&:hover": {
                            transform: "translateY(-2px)",
                            boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                        },
                    }}
                >
                    <Typography
                        fontWeight={600}
                        fontSize="16px"
                        lineHeight="18px"
                        color="white"
                    >
                        {notification.type === NotificationType.Chat
                            ? "Go to Chat"
                            : "View Booking"}
                    </Typography>
                </ButtonBase>
            </Stack>
        </Stack>
    );
};

export default NotificationDetails;
