import React, { useCallback, useEffect, useState } from "react";
import { Grid, Stack, Typography, useTheme } from "@mui/material";
import ColoredIcon from "../ColoredIcon";
import GalleryImage from "../GalleryImage";
import TabHeader from "../TabHeader";
import useWindowSize from "../../modules/useWindonSize";
import WalletIcon from "../../img/Wallet.svg";
import ClockIcon from "../../img/Clock.svg";
import Button from "../Button";
import Service from "../../api/Service";
import ServiceGallery from "../../api/ServiceGallery";
import { useLoaderData, useNavigate } from "react-router-dom";
import Bg from "../Bg";
import Header from "../Header";
import { useAddress } from "../../contexts/AddressContext";

export const loader = async (request: any) => {
    //const category_id = await request.params.category_id;
    const service_id = await request.params.service_id;
    return { service_id };
};

const ServiceInfo = () => {
    const address = useAddress();
    //const category_id = (useLoaderData() as any).category_id as string;
    const service_id = (useLoaderData() as any).service_id as string;

    const theme = useTheme();
    const navigate = useNavigate();

    const windowSize = useWindowSize();

    const [service, setService] = useState<Service | undefined>();
    const [image, setImage] = useState<string | undefined>();

    useEffect(() => {
        Service.info(address, service_id)
            .then((res) => {
                setService(res);
                setImage(res?.image);
            })
            .catch(console.error);
    }, [address, service_id]);

    const [images, setImages] = useState<string[]>([]);

    useEffect(() => {
        ServiceGallery.list(address, service_id)
            .then((res) => setImages((res ?? []).map((x) => x.image!)))
            .catch(console.error);
    }, [address, service_id]);

    const onBook = useCallback(
        () => navigate("/services/" + service_id + "/date"),
        [service_id, navigate]
    );

    return (
        <Stack
            direction="column"
            width="100%"
            height="100vh"
            justifyContent="center"
            alignItems="center"
        >
            <Bg />
            <Header />
            <TabHeader
                name="Service to book"
                onClose={() => navigate(`/services`)}
            />
            <Stack
                className="force-dynamic-translation"
                height="100%"
                width="100%"
                alignItems="center"
                spacing={1}
            >
                <Stack
                    width="100%"
                    height="100%"
                    alignItems="center"
                    margin={2}
                    paddingBottom={15}
                    overflow="scroll"
                >
                    {image && (
                        <GalleryImage
                            width={windowSize[0]}
                            height={windowSize[0]}
                            image={image}
                            sx={{
                                minHeight: windowSize[0],
                            }}
                        />
                    )}
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                        paddingY={2}
                        bgcolor="white"
                    >
                        <Stack
                            spacing={1}
                            justifyContent="flex-start"
                            width="90%"
                        >
                            <Typography
                                fontWeight={500}
                                fontSize="20px"
                                lineHeight="20.8px"
                            >
                                {service?.name}
                            </Typography>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={4}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <ColoredIcon
                                        icon={WalletIcon}
                                        color="black"
                                        size="20px"
                                        style={{ opacity: "50%" }}
                                    />
                                    <Typography
                                        fontWeight={700}
                                        fontSize="20px"
                                        lineHeight="30px"
                                        color={theme.palette.primary.main}
                                    >
                                        € {service?.price}
                                    </Typography>
                                </Stack>
                                <FormattedTime
                                    time={service?.duration ?? 60}
                                    size="big"
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        width="90%"
                        justifyContent="center"
                        alignItems="center"
                        paddingY={2}
                    >
                        <Typography
                            fontWeight={400}
                            fontSize="14px"
                            lineHeight="18.2px"
                        >
                            {service?.description}
                        </Typography>
                    </Stack>
                    <Stack
                        width="90%"
                        justifyContent="center"
                        alignItems="center"
                        paddingY={2}
                    >
                        <Grid container width="100%">
                            {images.map((image, index) => (
                                <Grid
                                    key={index}
                                    item
                                    xs={4}
                                    width="118px"
                                    height="118px"
                                    paddingY={8}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <GalleryImage
                                        width="118px"
                                        height="118px"
                                        borderRadius="20px"
                                        image={image}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                </Stack>
                <Stack width="90%" position="absolute" bottom={30}>
                    <Button variant="large" fullWidth onClick={onBook}>
                        Book
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
};

const FormattedTime = (props: { time: number; size: "big" | "small" }) => {
    const { time, size } = props;

    const hours = Math.floor(time / 60);
    const mins = Math.floor(time % 60);

    return (
        <Stack direction="row" alignItems="flex-end" spacing={1}>
            <ColoredIcon
                icon={ClockIcon}
                color="black"
                size="20px"
                style={{ opacity: "50%", paddingBottom: 6 }}
            />

            {hours > 0 ? (
                <Stack
                    direction="row"
                    alignItems="flex-end"
                    spacing={size === "big" ? 0.5 : 0.25}
                >
                    <Typography
                        fontWeight={400}
                        fontSize={size === "big" ? "20px" : "16px"}
                        lineHeight={size === "big" ? "30px" : "24px"}
                        color="#1A1A1A"
                    >
                        {hours}
                    </Typography>
                    <Typography
                        fontWeight={400}
                        fontSize="14px"
                        lineHeight={size === "big" ? "26px" : "22px"}
                        color="#1A1A1A"
                    >
                        h
                    </Typography>
                </Stack>
            ) : undefined}
            <Stack
                direction="row"
                alignItems="flex-end"
                spacing={size === "big" ? 0.5 : 0.25}
            >
                <Typography
                    fontWeight={400}
                    fontSize={size === "big" ? "20px" : "16px"}
                    lineHeight={size === "big" ? "30px" : "24px"}
                    color="#1A1A1A"
                >
                    {mins}
                </Typography>
                <Typography
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight={size === "big" ? "26px" : "22px"}
                    color="#1A1A1A"
                >
                    min
                </Typography>
            </Stack>
        </Stack>
    );
};

export default ServiceInfo;
