import React, { useEffect, useState } from "react";
import {
    Box,
    ButtonBase,
    Modal,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import Brand from "../api/Brand";
import ColoredIcon from "./ColoredIcon";
import DefaultIcon from "../img/default_icon.svg";
import NotificationIcon from "../img/Notify_Passive.svg";
import NotificationActiveIcon from "../img/Notify_Active.svg";
import MenuIcon from "../img/Sandvich.svg";
import Menu from "./Menu";
import { useCookies } from "react-cookie";
import User from "../api/User";
import { useAddress } from "../contexts/AddressContext";
import { useNavigate } from "react-router-dom";
// import subscribe from "../modules/Subscription";

const Header = () => {
    const navigate = useNavigate();
    const address = useAddress();

    const theme = useTheme();

    const [showMenu, setShowMenu] = useState(false);
    const [newNotifications, setNewNotifications] = useState(false);

    const [brand, setBrand] = useState<Brand | undefined>();
    const [logo, setLogo] = useState<string | undefined>();

    const [cookies] = useCookies(["client_id"]);
    const { client_id } = cookies;

    useEffect(() => {
        if (!client_id) return;
        // subscribe(cookies.client_id); // TODO: Make subscription
        User.info(client_id, address)
            .then((res) => setNewNotifications((res?.notifications ?? 0) > 0))
            .catch(console.error);
    }, [client_id, address]);

    useEffect(() => {
        Brand.info(address)
            .then((res) => {
                setBrand(res);
                setLogo(res?.logo);
            })
            .catch(console.error);
    }, [address]);

    const onNotificationsClick = () => navigate(`/notifications`);
    const onMenuClick = () => setShowMenu(true);
    const onMenuClose = () => setShowMenu(false);

    const handleRequestPermission = async () => {
        console.log("Requesting notification permissions...");
        const permission = await Notification.requestPermission();
        console.log("Notification permission status:", permission);

        if (permission === "granted") {
            console.log("Notifications allowed!");
        } else if (permission === "denied") {
            console.warn("Notifications are blocked by the user.");
        } else {
            console.log("Notifications permission request dismissed.");
        }
    };

    return (
        <Stack
            width="100%"
            height="48px"
            minHeight="48px"
            maxHeight="48px"
            alignItems="center"
            justifyContent="center"
            bgcolor="white"
        >
            <Stack
                direction="row"
                width="90%"
                height="100%"
                minHeight="48px"
                maxHeight="48px"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
            >
                <Stack
                    direction="row"
                    height="48px"
                    minHeight="48px"
                    maxHeight="48px"
                    alignItems="center"
                    spacing={2}
                >
                    <Box
                        width="38px"
                        height="38px"
                        borderRadius="38px"
                        bgcolor={theme.palette.primary.main}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {logo ? (
                            <Box
                                width="100%"
                                height="100%"
                                borderRadius="22px"
                                sx={{
                                    backgroundImage: `url(${logo})`,
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                }}
                            />
                        ) : (
                            <ColoredIcon
                                icon={DefaultIcon}
                                size="75%"
                                color="white"
                            />
                        )}
                    </Box>
                    <Typography
                        fontWeight={500}
                        fontSize="19px"
                        lineHeight="21.28px"
                    >
                        {brand?.name}
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    height="48px"
                    minHeight="48px"
                    maxHeight="48px"
                    alignItems="center"
                    spacing={1}
                >
                    {cookies.client_id !== undefined && (
                        <ButtonBase
                            onClick={handleRequestPermission}
                            sx={{
                                width: "32px",
                                height: "32px",
                                borderRadius: "32px",
                            }}
                        >
                            <ColoredIcon
                                size="100%"
                                icon={
                                    newNotifications
                                        ? NotificationActiveIcon
                                        : NotificationIcon
                                }
                            />
                        </ButtonBase>
                    )}
                    <ButtonBase
                        onClick={onMenuClick}
                        sx={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "32px",
                        }}
                    >
                        <ColoredIcon size="100%" icon={MenuIcon} />
                    </ButtonBase>
                </Stack>
                <Modal open={showMenu} onClose={onMenuClose}>
                    <Menu onClose={onMenuClose} />
                </Modal>
            </Stack>
        </Stack>
    );
};

export default Header;
