export const getEnvironment = () => {
    // CRA is using .env automatically for local builds

    // Check that variables are loaded
    if (
        !process.env.REACT_APP_GOOGLE_CLIENT_ID ||
        !process.env.REACT_APP_WEGLOT_API_KEY ||
        !process.env.REACT_APP_SERVER_ADDRESS ||
        !process.env.REACT_APP_API_URL ||
        !process.env.REACT_APP_VAPID_KEY ||
        !process.env.REACT_APP_STARTER_URL
    ) {
        throw new Error("Missing required environment variables");
    }

    return {
        google_client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        weglot_api_key: process.env.REACT_APP_WEGLOT_API_KEY,
        server_address: process.env.REACT_APP_SERVER_ADDRESS,
        api_url: process.env.REACT_APP_API_URL,
        vapid_key: process.env.REACT_APP_VAPID_KEY,
        starter_url: process.env.REACT_APP_STARTER_URL,
        brands_list: process.env.REACT_APP_BRANDS_LIST, // For testing purposes only
    } as const;
};
