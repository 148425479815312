import { useEffect } from "react";
import { getEnvironment } from "../utils/getEnvironment";

declare global {
    interface Window {
        Weglot?: {
            initialize: (config: {
                api_key: string;
                auto_switch: boolean;
                wait_transition?: boolean;
                extra_definitions?: Array<{
                    type: number;
                    selector: string;
                    attribute: string;
                }>;
            }) => void;
        };
    }
}

const environment = getEnvironment();

const useWeglot = () => {
    useEffect(() => {
        if (window.Weglot) {
            window.Weglot.initialize({
                api_key: environment.weglot_api_key,
                auto_switch: true,
                wait_transition: true,
            });
        }
    }, []);
};

export default useWeglot;
