import React, { useCallback, useEffect, useState } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import WalletIcon from "../../img/Wallet.svg";
import ClockIcon from "../../img/Clock.svg";
import ColoredIcon from "../ColoredIcon";
import { Dayjs } from "dayjs";
import TabHeader from "../TabHeader";
import Button from "../Button";
import Service from "../../api/Service";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Bg from "../Bg";
import Header from "../Header";
import Booking from "../../api/Booking";
import { parseWithOffset } from "../../utils/dayjsUtils";
import { useAddress } from "../../contexts/AddressContext";

export const loader = async (request: any) => {
    const service_id = await request.params.service_id;
    return { service_id };
};

const Confirmation = () => {
    const navigate = useNavigate();
    const address = useAddress();
    const service_id = (useLoaderData() as any).service_id as string;

    const theme = useTheme();

    const [cookies] = useCookies(["client_id", "client_location_id"]);
    const { client_id, client_location_id } = cookies;

    const [service, setService] = useState<Service | undefined>();
    const [dateTime, setDateTime] = useState<Dayjs | undefined>();

    useEffect(() => {
        const dateTime = sessionStorage.getItem("selected_dateTime");
        setDateTime(dateTime ? parseWithOffset(dateTime) : undefined);

        Service.info(address, service_id).then(setService).catch(console.error);
    }, [address, service_id]);

    useEffect(() => {
        if (!client_id || !client_location_id || !service) return;

        const service_id = sessionStorage.getItem("selected_service");
        if (service_id) {
            sessionStorage.removeItem("selected_service");
            Booking.register(
                client_id,
                address,
                client_location_id,
                service.key,
                service.duration,
                service.price,
                dateTime?.toDate(),
                dateTime?.add(service.duration, "minute")?.toDate()
            )
                .then(() => navigate(`/services/done`))
                .catch(console.error);
        }
    }, [client_id, client_location_id, address, service, dateTime, navigate]);

    const onConfirm = useCallback(() => {
        if (!service) return;
        if (!client_id) {
            sessionStorage.setItem("selected_service", service.key.toString());
            navigate(`/services/login`);
            return;
        }
        Booking.register(
            client_id,
            address,
            client_location_id,
            service.key,
            service.duration,
            service.price,
            dateTime?.toDate(),
            dateTime?.add(service.duration, "minute")?.toDate()
        )
            .then(() => navigate(`/services/done`))
            .catch(console.error);
    }, [address, dateTime, client_id, client_location_id, service, navigate]);

    return (
        <Stack
            direction="column"
            width="100%"
            height="100vh"
            justifyContent="center"
            alignItems="center"
        >
            <Bg />
            <Header />
            <TabHeader
                name="Confirmation"
                onClose={() => navigate("/services/" + service_id + "/date")}
            />
            <Stack
                className="force-dynamic-translation"
                height="100%"
                width="100%"
                alignItems="center"
                spacing={1}
            >
                <Stack
                    direction="column"
                    width="264px"
                    paddingY={5}
                    spacing={2}
                >
                    {dateTime ? (
                        <Stack direction="column" spacing={1}>
                            <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="18.2px"
                                textAlign="center"
                                sx={{ opacity: "80%" }}
                            >
                                You have selected a service for a
                            </Typography>
                            <Typography
                                fontWeight={700}
                                fontSize="17px"
                                lineHeight="22.1px"
                                textAlign="center"
                                color={theme.palette.primary.main}
                            >
                                {dateTime.format("DD.MM.YYYY - HH:mm")}
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack direction="column" spacing={1}>
                            <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="18.2px"
                                textAlign="center"
                                sx={{ opacity: "80%" }}
                            >
                                You have selected a service with no specific
                                time. You will be added to Waitlist with status:
                            </Typography>
                            <Typography
                                fontWeight={700}
                                fontSize="17px"
                                lineHeight="22.1px"
                                textAlign="center"
                                color={theme.palette.primary.main}
                            >
                                As soon as possible
                            </Typography>
                        </Stack>
                    )}
                    <Typography
                        fontWeight={400}
                        fontSize="14px"
                        lineHeight="18.2px"
                        textAlign="center"
                        sx={{ opacity: "80%" }}
                    >
                        Now, please confirm your appointment.
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    paddingTop={2}
                    width="90%"
                    alignItems="center"
                    justifyContent="flex-start"
                >
                    <Typography
                        fontWeight={600}
                        fontSize="14px"
                        lineHeight="18.2px"
                    >
                        Selected service
                    </Typography>
                </Stack>
                <Box
                    width="100%"
                    height="1px"
                    bgcolor={theme.palette.primary.main}
                />
                <Stack direction="column" width="90%">
                    {service && <ServiceItem service={service} />}
                </Stack>
                <Stack width="90%" position="absolute" bottom={30}>
                    <Button variant="large" fullWidth onClick={onConfirm}>
                        Book
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
};

const ServiceItem = (props: { service: Service }) => {
    const theme = useTheme();
    const { service } = props;

    return (
        <Stack
            key={service.name}
            direction="row"
            spacing={2}
            alignItems="center"
            padding={2}
            width="100%"
        >
            <Box
                width="80px"
                minWidth="80px"
                height="80px"
                minHeight="80px"
                borderRadius="14px"
                bgcolor={theme.palette.primary.main}
                sx={{
                    backgroundImage: `url(${service.image})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            />
            <Stack spacing={2} justifyContent="flex-start">
                <Typography
                    fontWeight={500}
                    fontSize="20px"
                    lineHeight="20.8px"
                >
                    {service.name}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={4}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <ColoredIcon
                            icon={WalletIcon}
                            color="black"
                            size="20px"
                            style={{ opacity: "50%" }}
                        />
                        <Typography
                            fontWeight={700}
                            fontSize="20px"
                            lineHeight="30px"
                            color={theme.palette.primary.main}
                        >
                            € {service.price}
                        </Typography>
                    </Stack>
                    <FormattedTime time={service.duration} size="big" />
                </Stack>
            </Stack>
        </Stack>
    );
};

const FormattedTime = (props: { time: number; size: "big" | "small" }) => {
    const { time, size } = props;

    const hours = Math.floor(time / 60);
    const mins = Math.floor(time % 60);

    return (
        <Stack direction="row" alignItems="flex-end" spacing={1}>
            <ColoredIcon
                icon={ClockIcon}
                color="black"
                size="20px"
                style={{ opacity: "50%", paddingBottom: 6 }}
            />

            {hours > 0 ? (
                <Stack
                    direction="row"
                    alignItems="flex-end"
                    spacing={size === "big" ? 0.5 : 0.25}
                >
                    <Typography
                        fontWeight={400}
                        fontSize={size === "big" ? "20px" : "16px"}
                        lineHeight={size === "big" ? "30px" : "24px"}
                        color="#1A1A1A"
                    >
                        {hours}
                    </Typography>
                    <Typography
                        fontWeight={400}
                        fontSize="14px"
                        lineHeight={size === "big" ? "26px" : "22px"}
                        color="#1A1A1A"
                    >
                        h
                    </Typography>
                </Stack>
            ) : undefined}
            <Stack
                direction="row"
                alignItems="flex-end"
                spacing={size === "big" ? 0.5 : 0.25}
            >
                <Typography
                    fontWeight={400}
                    fontSize={size === "big" ? "20px" : "16px"}
                    lineHeight={size === "big" ? "30px" : "24px"}
                    color="#1A1A1A"
                >
                    {mins}
                </Typography>
                <Typography
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight={size === "big" ? "26px" : "22px"}
                    color="#1A1A1A"
                >
                    min
                </Typography>
            </Stack>
        </Stack>
    );
};

export default Confirmation;
