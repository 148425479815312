import React, { useEffect, useState } from "react";
import { Box, ButtonBase, Stack, Typography, useTheme } from "@mui/material";
import Bg from "./Bg";
import TabHeader from "./TabHeader";
import Notification, { NotificationType } from "../api/Notification";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { parseWithOffset } from "../utils/dayjsUtils";
import { useAddress } from "../contexts/AddressContext";

const NotificationsList = () => {
    const navigate = useNavigate();

    const address = useAddress();

    const [cookies] = useCookies(["client_id"]);
    const { client_id } = cookies;

    const [notifications, setNotifications] = useState<Notification[]>([]);

    useEffect(() => {
        if (!client_id) navigate(`/${address}`);

        Notification.list(client_id, address)
            .then((res) => setNotifications(res ?? []))
            .catch(console.error);
    }, [address, client_id, navigate]);

    const onExit = () => {
        navigate("/");
    };

    const onNotificationClick = (notificationKey: string) => {
        navigate(`/${address}/notifications/${notificationKey}`);
    };

    return (
        <Stack
            height="100vh"
            width="100%"
            alignItems="center"
            bgcolor="#F9F9F9"
        >
            <Bg />
            <TabHeader name="Notifications" onClose={onExit} />
            <Stack
                direction="column"
                width="100%"
                height="100%"
                paddingY={2}
                paddingBottom={10}
                justifyContent="flex-start"
                alignItems="center"
                overflow="scroll"
                spacing={3}
                className="force-dynamic-translation"
            >
                {notifications
                    .sort((a, b) =>
                        parseWithOffset(b.created).diff(
                            parseWithOffset(a.created)
                        )
                    )
                    .map((x) => (
                        <Item
                            key={x.key}
                            notification={x}
                            onClick={() => onNotificationClick(x.key)}
                        />
                    ))}
            </Stack>
        </Stack>
    );
};

const Item = (props: {
    //client_id: string;
    //address: string;
    notification: Notification;
    onClick: (notificationKey: string) => void;
}) => {
    const theme = useTheme();

    const { notification, onClick } = props;

    const bg = "white";
    const fg = "#1A1A1A";

    const isNew = notification.viewed === null;
    const title =
        notification.type === NotificationType.Booking ? "Booking" : "Chat";

    const truncateText = (text: string, maxLength: number) => {
        return text.length > maxLength
            ? `${text.slice(0, maxLength)}...`
            : text;
    };

    return (
        <ButtonBase
            onClick={(event) => onClick(notification.key)}
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "95%",
                borderRadius: "14px",
                boxShadow: "0px 4px 17px 0px rgba(0, 0, 0, 0.04)",
                bgcolor: bg,
                textTransform: "none",
                textAlign: "start",
            }}
        >
            {/* New Notification Indicator */}
            <Box
                width="8px"
                height="8px"
                bgcolor={isNew ? theme.palette.primary.main : "transparent"}
                marginLeft={2}
                marginTop={3}
                borderRadius="50%"
            />
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                width="80%"
                padding={2}
                paddingLeft={1}
                //height="70%"
            >
                <Typography
                    fontWeight={700}
                    fontSize="16px"
                    lineHeight="20px"
                    color={theme.palette.primary.main}
                    sx={{
                        textTransform: "capitalize",
                        paddingBottom: "4px",
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight="22px"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    color={fg}
                    marginBottom="8px"
                >
                    {truncateText(notification.message, 80)}
                </Typography>
                <Typography
                    fontWeight={400}
                    fontSize="12px"
                    lineHeight="18px"
                    color="#6D6D6D"
                >
                    {parseWithOffset(notification.created).format(
                        "DD.MM.YYYY HH:MM"
                    )}
                </Typography>
            </Stack>
        </ButtonBase>
    );
};

export default NotificationsList;
