import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";

const environment = getEnvironment();

interface MessageRaw {
    key: string;
    isUser: boolean;
    message: string;
    created: Date;
    viewed?: Date;
    image?: string | null;
}

export default class Message {
    key: string;
    isUser: boolean;
    message: string;
    created: Date;
    image?: string | null;
    viewed?: Date;

    constructor(data: MessageRaw) {
        this.key = data.key;
        this.isUser = data.isUser;
        this.message = data.message;
        this.created = data.created;
        this.viewed = data.viewed;
        this.image = data.image;
    }

    public static readonly list = async (uuid: string, brand: string) =>
        (
            (await axios
                .get(environment.api_url + "/" + brand + "/chat/list", {
                    headers: {
                        "User-Token": uuid,
                    },
                    timeout: 5000,
                })
                .then((res) => (res.status === 200 ? res.data : undefined))
                .catch(console.error)) as MessageRaw[] | undefined
        )?.map((x) => new Message(x));

    public static readonly send = async (
        uuid: string,
        brand: string,
        message: string,
        image?: string | null
    ) => {
        return axios.post(
            environment.api_url + "/" + brand + "/chat",
            {
                message,
                image,
            },
            {
                headers: {
                    "User-Token": uuid,
                },
            }
        );
    };

    public static readonly viewed = async (
        uuid: string,
        brand: string,
        message: string
    ) =>
        axios.put(
            environment.api_url + "/" + brand + "/chat/" + message,
            undefined,
            {
                headers: {
                    "User-Token": uuid,
                },
                timeout: 5000,
            }
        );
}
