import React, { useCallback, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import Button from "../Button";
import PasswordField from "../PasswordField";
import { useCookies } from "react-cookie";
import Bg from "../Bg";
import TabHeader from "../TabHeader";
import User from "../../api/User";
import { useNavigate } from "react-router-dom";
import { useAddress } from "../../contexts/AddressContext";

const ChangePassword = () => {
    const navigate = useNavigate();
    const address = useAddress();

    const [cookies] = useCookies(["client_id"]);
    const { client_id } = cookies;

    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!client_id) {
            navigate(`/`);
            return;
        }
    }, [client_id, address, navigate]);

    const changePassword = useCallback(() => {
        if (!client_id) return;
        if (password === "" || newPassword === "") {
            alert("Fill all the fields.");
            return;
        }
        setLoading(true);
        User.changePassword(client_id, address, password, newPassword)
            .then(() => {
                navigate(`/`);
            })
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [client_id, address, password, newPassword, navigate]);

    return (
        <Stack height="100vh" width="100%" alignItems="center">
            <Bg />
            <TabHeader
                name="Change Password"
                onClose={() => navigate(-1 || "/")}
            />
            <Stack
                width="80%"
                height="100%"
                alignItems="center"
                justifyContent="center"
                spacing={8}
                marginTop={8}
            >
                <Stack spacing={1} width="100%">
                    <PasswordField
                        variant="contained"
                        fullWidth
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                    <PasswordField
                        variant="contained"
                        fullWidth
                        placeholder="New password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.currentTarget.value)}
                    />
                </Stack>
                <Button
                    variant="big"
                    disabled={loading}
                    onClick={() => changePassword()}
                    color="black"
                    fullWidth
                >
                    Confirm
                </Button>
            </Stack>
        </Stack>
    );
};

export default ChangePassword;
