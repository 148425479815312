import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";
import { Dayjs } from "dayjs";

const environment = getEnvironment();

export enum BookingState {
    Wait = "wait",
    Done = "done",
    Cancel = "cancel",
}

export default class Booking {
    key: string;
    service: string;
    duration: number;
    price: number;
    start: Dayjs;
    end: Dayjs;
    state: BookingState;
    confirmed: boolean;

    constructor(
        key: string,
        service: string,
        duration: number,
        price: number,
        start: Dayjs,
        end: Dayjs,
        state: BookingState = BookingState.Wait,
        confirmed: boolean = false
    ) {
        this.key = key;
        this.service = service;
        this.duration = duration;
        this.price = price;
        this.start = start;
        this.end = end;
        this.state = state;
        this.confirmed = confirmed;
    }

    public static readonly register = async (
        uuid: string,
        brand: string,
        location: string,
        service: string,
        duration: number,
        price: number,
        start?: Date,
        end?: Date
    ) =>
        (
            await axios
                .post(
                    environment.api_url + "/" + brand + "/location/" + location,
                    {
                        service,
                        duration,
                        price,
                        start: start?.toISOString(),
                        end: end?.toISOString(),
                    },
                    {
                        headers: {
                            "User-Token": uuid,
                        },
                    }
                )
                .then((res) => (res.status === 200 ? res.data : undefined))
                .catch(console.error)
        ).key as string | undefined;

    public static readonly update = async (
        uuid: string,
        brand: string,
        location: string,
        booking: string,
        start: Date
    ) =>
        axios.put(
            environment.api_url +
                "/" +
                brand +
                "/location/" +
                location +
                "/" +
                booking,
            {
                start: start.toISOString(),
            },
            {
                headers: {
                    "User-Token": uuid,
                },
            }
        );

    public static readonly updateState = async (
        uuid: string,
        brand: string,
        location: string,
        booking: string,
        state: BookingState,
        start?: Date
    ) =>
        axios.put(
            environment.api_url +
                "/" +
                brand +
                "/location/" +
                location +
                "/" +
                booking,
            {
                state,
                start: start?.toISOString(),
            },
            {
                headers: {
                    "User-Token": uuid,
                },
            }
        );

    public static readonly updateConfirmed = async (
        uuid: string,
        brand: string,
        location: string,
        booking: string,
        confirmed: boolean
    ) =>
        axios.put(
            environment.api_url +
                "/" +
                brand +
                "/location/" +
                location +
                "/" +
                booking,
            {
                confirmed,
            },
            {
                headers: {
                    "User-Token": uuid,
                },
            }
        );

    public static readonly list = async (
        uuid: string,
        brand: string,
        location: string
    ) =>
        (await axios
            .get(
                environment.api_url +
                    "/" +
                    brand +
                    "/location/" +
                    location +
                    "/list",
                {
                    headers: {
                        "User-Token": uuid,
                    },
                }
            )
            .then((res) => (res.status === 200 ? res.data : undefined))
            .catch(console.error)) as Booking[] | undefined;

    public static readonly info = async (
        uuid: string,
        brand: string,
        location: string,
        booking: string
    ) =>
        (await axios
            .get(
                environment.api_url +
                    "/" +
                    brand +
                    "/location/" +
                    location +
                    "/" +
                    booking,
                {
                    headers: {
                        "User-Token": uuid,
                    },
                }
            )
            .then((res) => (res.status === 200 ? res.data : undefined))
            .catch(console.error)) as Booking | undefined;

    public static readonly listPeriod = async (
        uuid: string,
        brand: string,
        location: string,
        year: number,
        month: number,
        day?: number
    ) =>
        (await axios
            .get(
                environment.api_url +
                    "/" +
                    brand +
                    "/location/" +
                    location +
                    "/period/" +
                    year +
                    "/" +
                    month +
                    (day !== undefined ? "/" + day : "") +
                    "/list",
                {
                    headers: {
                        "User-Token": uuid,
                    },
                }
            )
            .then((res) => (res.status === 200 ? res.data : undefined))
            .catch(console.error)) as Booking[] | undefined;

    public static readonly listUser = async (
        uuid: string,
        brand: string,
        location: string,
        type: "active" | "waiting" | "history"
    ) =>
        (await axios
            .get(
                environment.api_url +
                    "/" +
                    brand +
                    "/location/" +
                    location +
                    "/" +
                    type +
                    "/list",
                {
                    headers: {
                        "User-Token": uuid,
                    },
                }
            )
            .then((res) => (res.status === 200 ? res.data : undefined))
            .catch(console.error)) as Booking[] | undefined;
}
