import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";

const environment = getEnvironment();

export default class Brand {
    name: string;
    address: string;
    color_red: number;
    color_green: number;
    color_blue: number;
    logo: string | null;

    constructor(
        name: string,
        address: string,
        color_red: number,
        color_green: number,
        color_blue: number,
        logo: string | null
    ) {
        this.name = name;
        this.address = address;
        this.color_red = color_red;
        this.color_green = color_green;
        this.color_blue = color_blue;
        this.logo = logo;
    }

    public static readonly getAll = async () =>
        axios.get(environment.api_url + "/devel/brands");
}
