import React, { useCallback, useState } from "react";
import Button, { ButtonProps } from "./Button";
import { Modal, Stack, Typography } from "@mui/material";

interface Props {
    children?: string;
    onCancel?: () => void;
    onConfirm?: (close: () => void, setLoading: (val: boolean) => void) => void;
    title?: string;
    cancelText?: string;
    confirmText?: string;
    button: ButtonProps;
}

const ConfirmButton = (props: Props) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { children, button, title, cancelText, confirmText } = props;

    const onCancel = useCallback(() => {
        setOpen(false);
        props.onCancel?.();
    }, [props]);

    const onConfirm = useCallback(() => {
        props.onConfirm?.(() => setOpen(false), setLoading);
    }, [props]);

    return (
        <>
            <Button
                {...button}
                children={children}
                onClick={() => setOpen(true)}
            />
            <Modal open={open} onClose={() => {}}>
                <ConfirmModal
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                    title={title}
                    cancelText={cancelText}
                    confirmText={confirmText}
                    loading={loading}
                />
            </Modal>
        </>
    );
};

const ConfirmModal = (props: {
    onCancel: () => void;
    onConfirm: () => void;
    title?: string;
    cancelText?: string;
    confirmText?: string;
    loading?: boolean;
}) => {
    const { onCancel, onConfirm, title, cancelText, confirmText, loading } =
        props;

    return (
        <Stack
            position="absolute"
            top="50%"
            left="50%"
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            width="85%"
            padding={2}
            bgcolor="white"
            borderRadius="14px"
            boxShadow="0px 4px 17px 0px #0000000A"
            sx={{
                transform: "translate(-50%, -50%)",
            }}
        >
            <Typography variant="h6" component="h2">
                {title ?? "Are you sure?"}
            </Typography>
            <Stack direction="row" width="100%" spacing={2}>
                <Button
                    variant="medium"
                    fullWidth
                    onClick={onCancel}
                    disabled={loading}
                >
                    {cancelText ?? "No"}
                </Button>
                <Button
                    variant="medium"
                    fullWidth
                    onClick={onConfirm}
                    disabled={loading}
                >
                    {confirmText ?? "Yes"}
                </Button>
            </Stack>
        </Stack>
    );
};

export default ConfirmButton;
