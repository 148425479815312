import React, { useCallback } from "react";
import { ButtonBase, Stack, useTheme } from "@mui/material";
import HomeIcon from "../img/Home.svg";
import ServicesIcon from "../img/Services.svg";
import ChatIcon from "../img/Chat.svg";
import ProfileIcon from "../img/Profile.svg";
import ColoredIcon from "./ColoredIcon";
import { useNavigate } from "react-router-dom";

interface Props {
    tab: "home" | "services" | "chat" | "profile";
}

const Footer = (props: Props) => {
    const { tab } = props;

    const navigate = useNavigate();

    const navigateTo = useCallback(
        (address: string) => {
            navigate(`/${address}`);
        },
        [navigate]
    );

    const onHomeClick = () => navigateTo("");
    const onServicesClick = () => navigateTo("services");
    const onChatClick = () => navigateTo("chat");
    const onProfileClick = () => navigateTo("profile");

    return (
        <Stack
            direction="row"
            width="100%"
            height="87px"
            minHeight="87px"
            maxHeight="87px"
            bgcolor="white"
            alignItems="flex-start"
            justifyContent="center"
            boxShadow="0px 4px 17px 0px rgba(0, 0, 0, 0.04)"
            position="fixed"
            bottom={0}
            paddingBottom="env(safe-area-inset-bottom)"
        >
            <Stack
                direction="row"
                width="80%"
                padding={1}
                alignItems="center"
                margin="5px"
                justifyContent="space-between"
                spacing={2}
            >
                <TabButton
                    icon={HomeIcon}
                    isSelected={tab === "home"}
                    onClick={onHomeClick}
                />
                <TabButton
                    icon={ServicesIcon}
                    isSelected={tab === "services"}
                    onClick={onServicesClick}
                />
                <TabButton
                    icon={ChatIcon}
                    isSelected={tab === "chat"}
                    onClick={onChatClick}
                />
                <TabButton
                    icon={ProfileIcon}
                    isSelected={tab === "profile"}
                    onClick={onProfileClick}
                />
            </Stack>
        </Stack>
    );
};

const TabButton = (props: {
    icon: string;
    isSelected?: boolean;
    onClick: () => void;
    isMain?: boolean;
}) => {
    const theme = useTheme();

    const { icon, onClick } = props;
    const isMain = props.isMain ?? false;

    if (isMain) {
        return (
            <ButtonBase
                onClick={onClick}
                sx={{
                    width: "51px",
                    height: "51px",
                    borderRadius: "51px",
                    backgroundColor: theme.palette.primary.main + "CD",
                }}
            >
                <ColoredIcon size="85%" icon={icon} color="white" />
            </ButtonBase>
        );
    } else {
        const isSelected = props.isSelected ?? false;
        const color = isSelected ? theme.palette.primary.main : "#26262680";

        return (
            <ButtonBase
                onClick={onClick}
                sx={{ width: "32px", height: "32px", borderRadius: "32px" }}
            >
                <ColoredIcon size="100%" icon={icon} color={color} />
            </ButtonBase>
        );
    }
};

export default Footer;
