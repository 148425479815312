import React from "react";
import { ButtonBase, Stack, Typography } from "@mui/material";
import ColoredIcon from "./ColoredIcon";
import BackIcon from "../img/Arrow_Left.svg";
import { useNavigate } from "react-router-dom";

interface Props {
    name: string;
    onClose: (navigate: (address: string) => void) => void;
    disabled?: boolean;
}

const TabHeader = (props: Props) => {
    const { name, onClose, disabled } = props;

    const navigate = useNavigate();

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="48px"
            minHeight="48px"
            maxHeight="48px"
            bgcolor="white"
        >
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                width="90%"
                height="100%"
            >
                <ButtonBase
                    onClick={() => onClose((path: string) => navigate(path))}
                    disabled={disabled}
                    sx={{ position: "absolute", left: "10px" }}
                >
                    <ColoredIcon icon={BackIcon} color="black" size="38px" />
                </ButtonBase>
                <Typography
                    fontWeight={600}
                    fontSize="18px"
                    lineHeight="23.4px"
                >
                    {name}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default TabHeader;
