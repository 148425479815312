import React, { useEffect, useState } from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import Header from "../Header";
import Bg from "../Bg";
import { useNavigate } from "react-router-dom";
import TabHeader from "../TabHeader";
import Contact from "../../api/Contact";
import { useAddress } from "../../contexts/AddressContext";

const Contacts = () => {
    const navigate = useNavigate();
    const address = useAddress();

    const [contact, setContact] = useState<Contact | undefined>();

    useEffect(() => {
        Contact.info(address).then(setContact).catch(console.error);
    }, [address]);

    return (
        <Stack
            direction="column"
            width="100%"
            height="100vh"
            justifyContent="center"
            alignItems="center"
        >
            <Bg />
            <Header />
            <TabHeader name="About Us" onClose={() => navigate(-1 || "/")} />
            <Stack
                width="100%"
                height="100%"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                paddingTop={2}
            >
                <Block name="Address" value={contact?.address ?? ""} />
                <Block name="Phone" value={contact?.phone ?? ""} />
                <Block name="E-Mail" value={contact?.contactEmail ?? ""} />
                <Block name="Instagram" value={contact?.instagram ?? ""} />
            </Stack>
        </Stack>
    );
};

const Block = (props: { name: string; value: string }) => {
    const theme = useTheme();

    const { name, value } = props;

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            width="85%"
            paddingX={2}
            bgcolor="white"
            borderRadius="14px"
            boxShadow="0px 4px 17px 0px #0000000A"
        >
            <Typography fontWeight={400} fontSize="12px" lineHeight="18px">
                {name}
            </Typography>
            <Stack
                direction="column"
                height="48px"
                justifyContent="center"
                alignItems="flex-start"
                width="70%"
                paddingX={2}
                borderBottom={`1px solid ${theme.palette.primary.main}`}
            >
                <Typography
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight="18.2px"
                >
                    {value}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default Contacts;
