import React from "react";
import { Stack, Button as MuiButton } from "@mui/material";
import Button from "./Button";
import FolderIcon from "../img/Folder.svg";
import { useFilePicker } from "use-file-picker";
import {
    FileAmountLimitValidator,
    FileTypeValidator,
    FileSizeValidator,
} from "use-file-picker/validators";

interface Props {
    text?: string;
    isText?: boolean;
    onUpload: (val?: string) => void;
}

const ImagePicker = (props: Props) => {
    const { text, onUpload } = props;
    const isText = props.isText ?? false;

    const { openFilePicker, loading } = useFilePicker({
        readAs: "DataURL",
        accept: "image/*",
        multiple: false,
        validators: [
            new FileAmountLimitValidator({ max: 1 }),
            new FileTypeValidator(["jpeg", "jpg", "png"]),
            new FileSizeValidator({ maxFileSize: 10 * 1024 * 1024 }), // 10 MB
        ],
        onFilesSelected: ({ errors }) => {
            if (errors) {
                console.error("File upload error:", errors);
            }
        },
        onFilesSuccessfullySelected: ({ filesContent }) => {
            const item = filesContent[0];
            if (item) {
                onUpload(item.content);
            }
        },
    });

    return (
        <Stack spacing={2}>
            {isText ? (
                <MuiButton
                    variant="text"
                    sx={{
                        textTransform: "none",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "18.2px",
                    }}
                    onClick={openFilePicker}
                    disabled={loading}
                >
                    {text ?? "Change photo"}
                </MuiButton>
            ) : (
                <Button
                    variant="medium"
                    color="transparent"
                    icon={FolderIcon}
                    onClick={openFilePicker}
                    disabled={loading}
                >
                    {text ?? "Upload Icon"}
                </Button>
            )}
        </Stack>
    );
};

export default ImagePicker;
