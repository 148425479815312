import { Box, Modal, Stack, ButtonBase, SxProps } from "@mui/material";
import React, { useState } from "react";
import CrossIcon from "../img/Sandvich_Cross.svg";
import Button from "./Button";
import BackIcon from "../img/Arrow_Left.svg";
import ColoredIcon from "./ColoredIcon";
import useWindowSize from "../modules/useWindonSize";

interface Props {
    width?: number | string;
    height?: number | string;
    borderRadius?: number | string;
    image: string;
    onRemove?: () => void;
    sx?: SxProps;
}

const GalleryImage = (props: Props) => {
    const [open, setOpen] = useState(false);

    const { width, height, borderRadius, image, onRemove, sx } = props;

    const windowSize = useWindowSize();

    return (
        <>
            <ButtonBase
                onClick={() => setOpen(true)}
                sx={{
                    width,
                    height,
                    borderRadius,
                    backgroundImage: `url(${image})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100%",
                    ...sx,
                }}
            />

            <Modal open={open} onClose={() => setOpen(false)}>
                <Stack
                    position="absolute"
                    top="50%"
                    left="50%"
                    sx={{
                        transform: "translate(-50%, -50%)",
                    }}
                    spacing={2}
                    alignItems="center"
                >
                    <ButtonBase
                        onClick={() => setOpen(false)}
                        sx={{ position: "absolute", top: "10px", left: "10px" }}
                    >
                        <ColoredIcon
                            icon={BackIcon}
                            color="white"
                            size="38px"
                        />
                    </ButtonBase>
                    <Box
                        width={Math.min(windowSize[0], windowSize[1] - 100)}
                        height={Math.min(windowSize[0], windowSize[1] - 100)}
                        sx={{
                            backgroundImage: `url(${image})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                        }}
                    />
                    {onRemove && (
                        <Button
                            color="primary"
                            variant="medium"
                            icon={CrossIcon}
                            onClick={() => {
                                setOpen(false);
                                onRemove();
                            }}
                        >
                            Remove
                        </Button>
                    )}
                </Stack>
            </Modal>
        </>
    );
};

export default GalleryImage;
