import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";

const environment = getEnvironment();

interface UserRaw {
    key: string;
    created: Date;
    name: string;
    birth: string;
    phone: string;
    instagram: string;
    email: string;
    status: string;
    unread: number;
    notifications: number;
    local: boolean;
    image?: string | null;
}

export default class User {
    key: string;
    created: Date;
    name: string;
    birth: string;
    phone: string;
    instagram: string;
    email: string;
    status: string;
    unread: number;
    notifications: number;
    local: boolean;
    image?: string | null;

    constructor(data: UserRaw) {
        this.key = data.key;
        this.created = data.created;
        this.name = data.name;
        this.birth = data.birth;
        this.phone = data.phone;
        this.instagram = data.instagram;
        this.email = data.email;
        this.status = data.status;
        this.unread = data.unread;
        this.notifications = data.notifications;
        this.local = data.local;
        this.image = data.image;
    }

    public static readonly info = async (uuid: string, brand: string) => {
        const data = (await axios
            .get(environment.api_url + "/" + brand + "/user", {
                headers: {
                    "User-Token": uuid,
                },
            })
            .then((res) => (res.status === 200 ? res.data : undefined))
            .catch(console.error)) as UserRaw | undefined;
        if (data) return new User(data);
        return undefined;
    };

    public static readonly update = async (
        uuid: string,
        brand: string,
        birth: string,
        phone: string,
        instagram: string,
        image?: string | null
    ) => {
        return await axios
            .put(
                environment.api_url + "/" + brand + "/user",
                {
                    birth,
                    phone,
                    instagram,
                    image,
                },
                {
                    headers: {
                        "User-Token": uuid,
                    },
                }
            )
            .then((res) => (res.status === 200 ? res.data : undefined))
            .catch(console.error);
    };

    public static readonly signIn = async (
        brand: string,
        email: string,
        password: string
    ) =>
        (
            await axios
                .put(environment.api_url + "/" + brand + "/signin", {
                    email,
                    password,
                })
                .then((res) => (res.status === 200 ? res.data : undefined))
                .catch(console.error)
        )?.key as string | undefined;

    public static readonly rkeySignIn = async (
        brand: string,
        rkey: string,
        password: string
    ) =>
        (
            await axios
                .put(environment.api_url + "/" + brand + "/signin", {
                    rkey,
                    password,
                })
                .then((res) => (res.status === 200 ? res.data : undefined))
                .catch(console.error)
        )?.key as string | undefined;

    public static readonly signUp = async (
        brand: string,
        name: string,
        email: string,
        password?: string
    ) =>
        (
            await axios
                .post(environment.api_url + "/" + brand + "/signup", {
                    name,
                    email,
                    password,
                })
                .then((res) => (res.status === 200 ? res.data : undefined))
                .catch(console.error)
        )?.key as string | undefined;

    public static readonly signOut = async (uuid: string, brand: string) =>
        await axios
            .get(environment.api_url + "/" + brand + "/signout", {
                headers: {
                    "User-Token": uuid,
                },
            })
            .then((res) => (res.status === 200 ? res.data : undefined))
            .catch(console.error);

    public static readonly changePassword = async (
        uuid: string,
        brand: string,
        password: String,
        new_password: String
    ) =>
        await axios.put(
            environment.api_url + "/" + brand + "/password",
            {
                password,
                new_password,
            },
            {
                headers: {
                    "User-Token": uuid,
                },
            }
        );
}
