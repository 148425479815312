import React, { useCallback, useEffect, useState } from "react";
import {
    ButtonBase as MuiButton,
    Box,
    Divider,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import WalletIcon from "../../img/Wallet.svg";
import ClockIcon from "../../img/Clock.svg";
import Bg from "../Bg";
import ColoredIcon from "../ColoredIcon";
import Category from "../../api/Category";
import Service from "../../api/Service";
import Footer from "../Footer";
import Header from "../Header";
import { useAddress } from "../../contexts/AddressContext";
import { useNavigate } from "react-router-dom";

const ServicesList = () => {
    const navigate = useNavigate();
    const address = useAddress();

    const [categories, setCategories] = useState<Category[]>([]);

    useEffect(() => {
        Category.list(address)
            .then((res) => setCategories(res ?? []))
            .catch(console.error);
    }, [address]);

    const onSelect = useCallback(
        (service: Service) => navigate("/services/" + service.key),
        [navigate]
    );

    return (
        <Stack
            direction="column"
            width="100%"
            height="100vh"
            justifyContent="center"
            alignItems="center"
        >
            <Bg />
            <Header />
            <Stack
                className="force-dynamic-translation"
                width="100%"
                height="100%"
                overflow="scroll"
                paddingBottom={20}
            >
                {categories.map((x) => (
                    <CategoryView
                        address={address}
                        category={x}
                        onSelect={onSelect}
                    />
                ))}
            </Stack>
            <Footer tab="services" />
        </Stack>
    );
};

const CategoryView = (props: {
    address: string;
    category: Category;
    onSelect: (val: Service) => void;
}) => {
    const { address, category, onSelect } = props;

    const [services, setServices] = useState<Service[]>([]);

    useEffect(() => {
        Service.list(address, category.key)
            .then((services) => {
                if (!services) return;
                setServices(services);
                const key = sessionStorage.getItem("selected_service");
                sessionStorage.removeItem("selected_service");
                const selected = services.find((x) => x.key === key);
                if (selected !== undefined) onSelect(selected);
            })
            .catch(console.error);
    }, [address, category, onSelect]);

    return (
        <Stack key={category.name} paddingBottom={3}>
            <Typography
                padding={2}
                paddingLeft={4}
                fontWeight={600}
                fontSize="18px"
                lineHeight="23.4px"
            >
                {category.name}
            </Typography>
            {services.map((x) => (
                <>
                    <ServiceView service={x} onSelect={onSelect} />
                    <Divider />
                </>
            ))}
        </Stack>
    );
};

const ServiceView = (props: {
    service: Service;
    onSelect: (val: Service) => void;
}) => {
    const theme = useTheme();
    const { service, onSelect } = props;

    return (
        <MuiButton
            sx={{
                padding: 0,
                margin: 0,
                borderRadius: "15px",
                border: "none",
                textTransform: "none",
                textAlign: "start",
            }}
            onClick={() => onSelect(service)}
        >
            <Stack
                key={service.name}
                direction="row"
                spacing={2}
                alignItems="center"
                padding={2}
                paddingLeft={4}
                width="100%"
            >
                <Box
                    width="80px"
                    minWidth="80px"
                    height="80px"
                    minHeight="80px"
                    borderRadius="14px"
                    bgcolor={theme.palette.primary.main}
                    sx={{
                        backgroundImage: `url(${service.image})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                />
                <Stack spacing={2} justifyContent="flex-start">
                    <Typography
                        fontWeight={500}
                        fontSize="20px"
                        lineHeight="20.8px"
                    >
                        {service.name}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={4}>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                        >
                            <ColoredIcon
                                icon={WalletIcon}
                                color="black"
                                size="20px"
                                style={{ opacity: "50%" }}
                            />
                            <Typography
                                fontWeight={700}
                                fontSize="20px"
                                lineHeight="30px"
                                color={theme.palette.primary.main}
                            >
                                € {service.price}
                            </Typography>
                        </Stack>
                        <FormattedTime time={service.duration} size="big" />
                    </Stack>
                </Stack>
            </Stack>
        </MuiButton>
    );
};

const FormattedTime = (props: { time: number; size: "big" | "small" }) => {
    const { time, size } = props;

    const hours = Math.floor(time / 60);
    const mins = Math.floor(time % 60);

    return (
        <Stack direction="row" alignItems="flex-end" spacing={1}>
            <ColoredIcon
                icon={ClockIcon}
                color="black"
                size="20px"
                style={{ opacity: "50%", paddingBottom: 6 }}
            />

            {hours > 0 ? (
                <Stack
                    direction="row"
                    alignItems="flex-end"
                    spacing={size === "big" ? 0.5 : 0.25}
                >
                    <Typography
                        fontWeight={400}
                        fontSize={size === "big" ? "20px" : "16px"}
                        lineHeight={size === "big" ? "30px" : "24px"}
                        color="#1A1A1A"
                    >
                        {hours}
                    </Typography>
                    <Typography
                        fontWeight={400}
                        fontSize="14px"
                        lineHeight={size === "big" ? "26px" : "22px"}
                        color="#1A1A1A"
                    >
                        h
                    </Typography>
                </Stack>
            ) : undefined}
            <Stack
                direction="row"
                alignItems="flex-end"
                spacing={size === "big" ? 0.5 : 0.25}
            >
                <Typography
                    fontWeight={400}
                    fontSize={size === "big" ? "20px" : "16px"}
                    lineHeight={size === "big" ? "30px" : "24px"}
                    color="#1A1A1A"
                >
                    {mins}
                </Typography>
                <Typography
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight={size === "big" ? "26px" : "22px"}
                    color="#1A1A1A"
                >
                    min
                </Typography>
            </Stack>
        </Stack>
    );
};

export default ServicesList;
