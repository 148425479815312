import React from "react";
import {
    Button as MButton,
    SxProps,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import ColoredIcon from "./ColoredIcon";

export interface ButtonProps {
    children?: string;
    variant?: "large" | "big" | "medium" | "small";
    color?: "primary" | "secondary" | "outlined" | "transparent" | "black";
    textColor?: string;
    icon?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    sx?: SxProps<Theme>;
    fullWidth?: boolean;
    wrap?: boolean;
}

const Button = (props: ButtonProps) => {
    const theme = useTheme();

    const { children, icon, onClick, disabled, sx, fullWidth, wrap } = props;
    const variant = props.variant ?? "large";
    const color = props.color ?? "primary";

    const height = () => {
        switch (variant) {
            case "large":
                return "56px";
            case "big":
                return "46px";
            case "medium":
                return "38px";
            default:
                return "32px";
        }
    };

    const fontWeight = () => {
        switch (variant) {
            case "large":
                return 600;
            case "big":
            case "medium":
            default:
                return 500;
        }
    };

    const fontSize = () => {
        switch (variant) {
            case "large":
            case "big":
                return "16px";
            case "medium":
                return "14px";
            default:
                return "12px";
        }
    };

    const lineHeight = () => {
        switch (variant) {
            case "large":
            case "big":
                return "22.4px";
            case "medium":
                return "21px";
            default:
                return "18px";
        }
    };

    const borderRadius = () => {
        switch (variant) {
            case "large":
            case "big":
                return "14px";
            case "medium":
            default:
                return "22px";
        }
    };

    const textColor = () => {
        if (props.textColor) return props.textColor;
        else
            switch (color) {
                case "primary":
                case "black":
                    return "secondary";
                case "outlined":
                    return "black";
                default:
                    return "primary";
            }
    };

    const iconColor = () => {
        if (props.textColor) return props.textColor;
        else
            switch (color) {
                case "primary":
                case "black":
                    return theme.palette.secondary.main;
                case "outlined":
                    return "black";
                default:
                    return theme.palette.primary.main;
            }
    };

    const customWidth = () =>
        !fullWidth && (variant === "medium" || variant === "small")
            ? "min-content"
            : undefined;

    return (
        <MButton
            fullWidth={fullWidth}
            variant="contained"
            color={
                color === "primary"
                    ? "primary"
                    : color === "secondary" || color === "outlined"
                    ? "secondary"
                    : color === "black"
                    ? "warning"
                    : "info"
            }
            startIcon={
                icon ? (
                    <ColoredIcon icon={icon} size="24px" color={iconColor()} />
                ) : undefined
            }
            disabled={disabled}
            sx={{
                height: height(),
                width: customWidth(),
                padding: "8px 20px",
                borderRadius: borderRadius(),
                border: color === "outlined" ? "1px solid" : "none",
                gap: "8px",
                textTransform: "none",
                ...sx,
            }}
            onClick={onClick}
        >
            <Typography
                fontWeight={fontWeight()}
                fontSize={fontSize()}
                lineHeight={lineHeight()}
                color={textColor()}
                noWrap={!wrap}
            >
                {children}
            </Typography>
        </MButton>
    );
};

export default Button;
