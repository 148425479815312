import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";

const environment = getEnvironment();

interface LocationRaw {
    key: string;
    name: string;
    city: string;
    address: string;
    description: string;
    image?: string;
    mo: boolean;
    moStart: number;
    moEnd: number;
    tu: boolean;
    tuStart: number;
    tuEnd: number;
    we: boolean;
    weStart: number;
    weEnd: number;
    th: boolean;
    thStart: number;
    thEnd: number;
    fr: boolean;
    frStart: number;
    frEnd: number;
    sa: boolean;
    saStart: number;
    saEnd: number;
    su: boolean;
    suStart: number;
    suEnd: number;
}

export default class Location {
    key: string;
    name: string;
    city: string;
    address: string;
    description: string;
    image?: string;
    mo: boolean;
    moStart: number;
    moEnd: number;
    tu: boolean;
    tuStart: number;
    tuEnd: number;
    we: boolean;
    weStart: number;
    weEnd: number;
    th: boolean;
    thStart: number;
    thEnd: number;
    fr: boolean;
    frStart: number;
    frEnd: number;
    sa: boolean;
    saStart: number;
    saEnd: number;
    su: boolean;
    suStart: number;
    suEnd: number;

    constructor(data: LocationRaw) {
        this.key = data.key;
        this.name = data.name;
        this.city = data.city;
        this.address = data.address;
        this.description = data.description;
        this.mo = data.mo;
        this.moStart = data.moStart;
        this.moEnd = data.moEnd;
        this.tu = data.tu;
        this.tuStart = data.tuStart;
        this.tuEnd = data.tuEnd;
        this.we = data.we;
        this.weStart = data.weStart;
        this.weEnd = data.weEnd;
        this.th = data.th;
        this.thStart = data.thStart;
        this.thEnd = data.thEnd;
        this.fr = data.fr;
        this.frStart = data.frStart;
        this.frEnd = data.frEnd;
        this.sa = data.sa;
        this.saStart = data.saStart;
        this.saEnd = data.saEnd;
        this.su = data.su;
        this.suStart = data.suStart;
        this.suEnd = data.suEnd;
        this.image = data.image;
    }

    public static readonly list = async (brand: string) =>
        (
            (await axios
                .get(environment.api_url + "/" + brand + "/location/list")
                .then((res) => (res.status === 200 ? res.data : undefined))
                .catch(console.error)) as LocationRaw[] | undefined
        )?.map((x) => new Location(x));

    public static readonly info = async (brand: string, location: string) => {
        const data = (await axios
            .get(environment.api_url + "/" + brand + "/location/" + location)
            .then((res) => (res.status === 200 ? res.data : undefined))
            .catch(console.error)) as LocationRaw | undefined;
        if (data) return new Location(data);
        return undefined;
    };

    private static readonly _free = async (
        brand: string,
        location: string,
        year: number,
        month: number,
        day?: number,
        week?: boolean
    ) =>
        (await axios
            .get(
                environment.api_url +
                    "/" +
                    brand +
                    "/location/" +
                    location +
                    "/free/" +
                    year +
                    "/" +
                    month +
                    (day !== undefined ? "/" + day : ""),
                {
                    params:
                        week === true
                            ? {
                                  week: true,
                              }
                            : undefined,
                }
            )
            .then((res) => (res.status === 200 ? res.data : undefined))
            .catch(console.error)) as number[] | number[][] | undefined;

    public static readonly freeMonth = async (
        brand: string,
        location: string,
        year: number,
        month: number
    ) => ((await this._free(brand, location, year, month)) ?? []) as number[][];

    public static readonly freeWeek = async (
        brand: string,
        location: string,
        year: number,
        month: number,
        day: number
    ) =>
        ((await this._free(brand, location, year, month, day, true)) ??
            []) as number[][];

    public static readonly freeDay = async (
        brand: string,
        location: string,
        year: number,
        month: number,
        day: number
    ) =>
        ((await this._free(brand, location, year, month, day)) ??
            []) as number[];
}
