import React, { useCallback, useEffect, useState } from "react";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import TextField from "../TextField";
import ImagePicker from "../ImagePicker";
import GalleryImage from "../GalleryImage";
import User from "../../api/User";
import { useCookies } from "react-cookie";
import { uploadToCloudinary } from "../../utils/cloudinaryUtils";
import { useAddress } from "../../contexts/AddressContext";

const ProfileInfo = () => {
    const address = useAddress();

    const theme = useTheme();

    const [cookies] = useCookies(["client_id"]);
    const { client_id } = cookies;

    const [image, setImage] = useState<string | null | undefined>(); // actual base64 string for upload
    const [imagePreview, setImagePreview] = useState<
        string | null | undefined
    >(); // either base64 or url to display
    const [birth, setBirth] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [instagram, setInstagram] = useState("");

    useEffect(() => {
        if (!client_id) return;
        User.info(client_id, address)
            .then((res) => {
                if (!res) return;
                setImagePreview(res.image);
                setBirth(res.birth);
                setPhone(res.phone);
                setEmail(res.email);
                setInstagram(res.instagram);
            })
            .catch(console.error);
    }, [client_id, address]);

    const update = useCallback(async () => {
        if (!client_id) return;
        User.update(client_id, address, birth, phone, instagram, image);
        setImage(image);
        setImagePreview(image);
    }, [client_id, address, birth, phone, instagram, image]);

    const onUserImageRemove = () => {
        setImage(null);
        setImagePreview(undefined);
    };

    const onUserImageUpload = async (base64Image?: string) => {
        if (!base64Image) return;

        try {
            // Upload to Cloudinary staging
            const stagingUrl = await uploadToCloudinary(base64Image);
            if (stagingUrl) {
                setImage(stagingUrl); // Staging URL for backend confirmation
                setImagePreview(stagingUrl); // For display purposes
                console.log("Image uploaded successfully:", stagingUrl);
            } else {
                console.error("Failed to upload image to Cloudinary.");
            }
        } catch (error) {
            console.error("Error during image upload:", error);
        }
    };

    useEffect(() => {
        if (image !== undefined) update();
    }, [image, update]);

    return (
        <Stack
            className="force-dynamic-translation"
            height="100%"
            width="100%"
            alignItems="center"
            spacing={2}
        >
            <Stack
                width="100%"
                height="100%"
                alignItems="center"
                margin={2}
                spacing={2}
                overflow="scroll"
                paddingBottom={20}
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    width="85%"
                    padding={2}
                    bgcolor="white"
                    borderRadius="14px"
                    boxShadow="0px 4px 17px 0px #0000000A"
                >
                    {imagePreview ? (
                        <GalleryImage
                            image={imagePreview}
                            width="94px"
                            height="94px"
                            borderRadius="94px"
                            onRemove={() => onUserImageRemove()}
                        />
                    ) : (
                        <Box
                            width="94px"
                            height="94px"
                            borderRadius="94px"
                            bgcolor={theme.palette.primary.main}
                        />
                    )}
                    <ImagePicker
                        onUpload={onUserImageUpload}
                        text="Change photo"
                        isText
                    />
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    width="85%"
                    paddingX={2}
                >
                    <Typography
                        fontWeight={600}
                        fontSize="14px"
                        lineHeight="18.2px"
                    >
                        Personal info
                    </Typography>
                </Stack>
                <Divider sx={{ width: "100%" }} />
                <Block
                    name="Date birth"
                    value={birth}
                    setValue={setBirth}
                    onBlur={update}
                />
                <Block
                    name="Phone"
                    value={phone}
                    setValue={setPhone}
                    onBlur={update}
                />
                <Block
                    name="E-Mail"
                    value={email}
                    setValue={setEmail}
                    onBlur={update}
                    disabled
                />
                <Block
                    name="Instagram"
                    value={instagram}
                    setValue={setInstagram}
                    onBlur={update}
                />
            </Stack>
        </Stack>
    );
};

const Block = (props: {
    name: string;
    value: string;
    setValue: (val: string) => void;
    onBlur: () => void;
    disabled?: boolean;
}) => {
    const { name, value, setValue, onBlur, disabled } = props;

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            width="85%"
            paddingX={2}
            bgcolor="white"
            borderRadius="14px"
            boxShadow="0px 4px 17px 0px #0000000A"
        >
            <Typography fontWeight={400} fontSize="12px" lineHeight="18px">
                {name}
            </Typography>
            <TextField
                value={value}
                onChange={(e) => setValue(e.currentTarget.value)}
                onBlur={onBlur}
                variant="underlined"
                disabled={disabled}
            />
        </Stack>
    );
};

export default ProfileInfo;
