import React, { useCallback, useEffect, useState } from "react";
import User from "../../api/User";
import { useCookies } from "react-cookie";
import { CircularProgress, Stack } from "@mui/material";
import Header from "../../components/Header";
import TextField from "../../components/TextField";
import PasswordField from "../../components/PasswordField";
import Button from "../../components/Button";
import GoogleIcon from "../../img/Google.svg";
import TabHeader from "../TabHeader";
import { useAddress } from "../../contexts/AddressContext";
import { useNavigate } from "react-router-dom";
import { getEnvironment } from "../../utils/getEnvironment";

interface Props {
    type: "profile" | "chat" | "services";
}

function Login(props: Props) {
    const navigate = useNavigate();
    const address = useAddress();
    const environment = getEnvironment();

    const { type } = props;

    const [loading, setLoading] = useState(true);

    const [cookies, setCookie] = useCookies(["client_id"]);
    const { client_id } = cookies;

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        if (client_id) {
            if (type === "services") {
                const service_id = sessionStorage.getItem("selected_service");
                if (service_id) {
                    navigate(`/services/${service_id}/confirmation`);
                } else {
                    navigate(`/services`);
                }
                return;
            }
            navigate(`/${type}`);
        } else setLoading(false);
    }, [client_id, address, type, navigate]);

    const handleGoogleLogin = () => {
        const state = JSON.stringify({
            address: address,
            returnUrl: window.location.href, // Deep link back to the current page
        });

        // Build the Google OAuth URL manually
        const googleOAuthURL =
            `https://accounts.google.com/o/oauth2/v2/auth?` +
            `client_id=${environment.google_client_id}&` +
            `redirect_uri=${environment.api_url}/oauth/callback&` +
            `response_type=code&` +
            `scope=email%20profile&` +
            `state=${encodeURIComponent(state)}`;

        // Redirect the user to the Google OAuth page
        window.location.href = googleOAuthURL;
    };

    const onLogin = useCallback(() => {
        setLoading(true);
        User.signIn(address, email, password)
            .then((res) => {
                if (!res) {
                    alert("Email or password is wrong.");
                    return;
                }
                setCookie("client_id", res, {
                    domain: "",
                    path: "/",
                });
            })
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [address, email, password, setCookie]);

    if (loading) return <CircularProgress />;

    return (
        <Stack
            className="force-dynamic-translation"
            height="100vh"
            width="100%"
            alignItems="center"
        >
            <Header />
            <TabHeader name="Sign In" onClose={() => navigate("/")} />
            <Stack
                width="80%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                spacing={8}
                marginTop={8}
            >
                <Stack spacing={1} width="100%">
                    <TextField
                        variant="contained"
                        fullWidth
                        placeholder="Email"
                        value={email}
                        onChange={(e) =>
                            setEmail(e.currentTarget.value.toLocaleLowerCase())
                        }
                    />
                    <PasswordField
                        variant="contained"
                        fullWidth
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                </Stack>
                <Stack spacing={1} width="100%">
                    <Button
                        variant="big"
                        disabled={loading}
                        onClick={onLogin}
                        color="black"
                        fullWidth
                    >
                        Log in
                    </Button>
                    <Button
                        variant="big"
                        icon={GoogleIcon}
                        disabled={loading}
                        onClick={handleGoogleLogin}
                        fullWidth
                    >
                        Log in with Google
                    </Button>
                </Stack>
                <Button
                    variant="large"
                    disabled={loading}
                    onClick={() => navigate(`/${type}/signup`)}
                    fullWidth
                >
                    Sign Up
                </Button>
            </Stack>
        </Stack>
    );
}

export default Login;
