import React, { useCallback, useEffect, useRef, useState } from "react";
import { Typography, Stack, useTheme } from "@mui/material";
import ChevronRight from "../img/Arrow_Right.svg";
import Paperclip from "../img/Paperclip.svg";
import TextField from "./TextField";
import Message from "../api/Message";
import { useCookies } from "react-cookie";
import Bg from "./Bg";
import Header from "./Header";
import Footer from "./Footer";
import dayjs, { Dayjs } from "dayjs";
import { useFilePicker } from "use-file-picker";
import {
    FileAmountLimitValidator,
    FileSizeValidator,
    FileTypeValidator,
} from "use-file-picker/validators";
import GalleryImage from "./GalleryImage";
import { uploadToCloudinary } from "../utils/cloudinaryUtils";
import { useAddress } from "../contexts/AddressContext";
import { useNavigate } from "react-router-dom";

export const loader = async (request: any) => {
    const client_id = await request.params.client_id;
    return { client_id };
};

const TextPromptModal = () => {
    const navigate = useNavigate();
    const address = useAddress();
    const [cookies] = useCookies(["client_id"]);
    const { client_id } = cookies;

    const [history, setHistory] = useState<Message[]>([]);
    const [message, setMessage] = useState("");
    const [messageLoading, setLoading] = useState(false);

    const [updater, setUpdater] = useState<NodeJS.Timer | undefined>();

    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (client_id === undefined) {
            navigate(`/chat/login`);
            return;
        }
    }, [client_id, navigate]);

    const scrollDown = () =>
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

    useEffect(scrollDown, [history]);

    const loadMessages = useCallback(async () => {
        if (!client_id) return;

        return Message.list(client_id, address)
            .then((res) =>
                setHistory(
                    (res ?? []).sort((a, b) =>
                        dayjs(a.created).diff(dayjs(b.created))
                    )
                )
            )
            .catch(console.error);
    }, [client_id, address]);

    useEffect(() => {
        if (!client_id || updater !== undefined) return;

        const id = setInterval(async () => {
            const res = await Message.list(client_id, address);
            if (res)
                setHistory((x) =>
                    x.length !== res.length
                        ? res.sort((a, b) =>
                              dayjs(a.created).diff(dayjs(b.created))
                          )
                        : x
                );
        }, 5000);

        setUpdater(id);
    }, [client_id, address, updater]);

    const sendMessage = useCallback(() => {
        if (!client_id) return;

        const toSend = message.trim();
        if (toSend.length === 0) return;
        setMessage("");
        setLoading(true);
        Message.send(client_id, address, toSend)
            .then(() => loadMessages())
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [client_id, address, message, loadMessages]);

    const sendImage = useCallback(
        async (base64Image: string) => {
            if (!client_id || !address) return;

            setLoading(true);
            try {
                // Upload the image to Cloudinary staging
                const stagingUrl = await uploadToCloudinary(base64Image);
                if (!stagingUrl) {
                    console.error("Failed to upload image to Cloudinary.");
                    return;
                }

                // Send the image message using the staging URL
                await Message.send(client_id, address, "image", stagingUrl);
                await loadMessages();
                console.log("Image sent successfully:", stagingUrl);
            } catch (error) {
                console.error("Error sending image:", error);
            } finally {
                setLoading(false);
            }
        },
        [client_id, address, loadMessages]
    );

    useEffect(() => {
        setLoading(true);
        loadMessages().finally(() => setLoading(false));
    }, [loadMessages]);

    const { openFilePicker, loading } = useFilePicker({
        readAs: "DataURL",
        accept: "image/*",
        multiple: false,
        validators: [
            new FileAmountLimitValidator({ max: 1 }),
            new FileTypeValidator(["jpeg", "jpg", "png"]),
            new FileSizeValidator({ maxFileSize: 10 * 1024 * 1024 }), // 10 MB
        ],
        onFilesSelected: ({ errors }) => {
            if (errors) {
                console.error("File upload error:", errors);
            }
        },
        onFilesSuccessfullySelected: ({ filesContent }) => {
            const item = filesContent[0];
            if (item) {
                sendImage(item.content);
            }
        },
    });

    return (
        <Stack
            direction="column"
            width="100%"
            height="100vh"
            alignItems="center"
            spacing={2}
        >
            <Bg />
            <Header />
            <Stack
                direction="column"
                height="100%"
                width="100%"
                overflow="scroll"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                paddingTop={2}
            >
                {history.map((x) => (
                    <MessageBox
                        client_id={client_id}
                        address={address}
                        message={x}
                    />
                ))}
                <div ref={messagesEndRef} style={{ marginTop: 100 }} />
            </Stack>
            <Stack width="90%" position="absolute" bottom={110}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.currentTarget.value)}
                    buttons={[
                        { icon: Paperclip, onClick: openFilePicker },
                        { icon: ChevronRight, onClick: sendMessage },
                    ]}
                    disabled={messageLoading || loading}
                    onKeyDown={(e) => e.key === "Enter" && sendMessage()}
                />
            </Stack>
            <Footer tab="chat" />
        </Stack>
    );
};

const MessageCloud = (props: {
    side: "left" | "right";
    content: string;
    type: "text" | "image";
}) => {
    const theme = useTheme();

    const { side, content, type } = props;

    const borderRadius =
        side === "left" ? "14px 14px 14px 0" : "14px 14px 0 14px";
    const bgcolor =
        theme.palette.primary.main + (side === "left" ? "1D" : "FF");
    const color = side === "left" ? "black" : "white";

    if (type === "image")
        return (
            <GalleryImage
                width="150px"
                height="150px"
                image={content}
                sx={{ bgcolor, borderRadius }}
            />
        );
    return (
        <Stack
            padding="10px 20px"
            bgcolor={bgcolor}
            borderRadius={borderRadius}
            maxWidth="75%"
        >
            <Typography
                fontWeight={400}
                fontSize="15px"
                lineHeight="19.5px"
                color={color}
                maxWidth="100%"
                sx={{
                    wordBreak: "break-word",
                }}
            >
                {content}
            </Typography>
        </Stack>
    );
};

const MessageBox = (props: {
    client_id: string;
    address: string;
    message: Message;
}) => {
    const { client_id, address, message } = props;

    const align = message.isUser ? "flex-end" : "flex-start";
    const side = message.isUser ? "right" : "left";
    const type = message.image ? "image" : "text";
    const content = message.image ?? message.message;

    useEffect(() => {
        if (!message.viewed && message.isUser)
            Message.viewed(client_id, address, message.key).catch(
                console.error
            );
    }, [client_id, address, message]);

    const time = (time: Dayjs) => {
        const now = dayjs();
        const minutes = now.diff(time, "minutes");
        const hours = now.diff(time, "hours");
        const days = now.diff(time, "days");
        if (days > 1) {
            return time.format("DD.MM.YYYY");
        } else if (days === 1) {
            return "Yesterday";
        } else if (days < 1) {
            if (hours >= 1) {
                return `${hours} h ago`;
            } else if (minutes >= 1) {
                return `${minutes} min ago`;
            } else {
                return `Now`;
            }
        }
    };

    return (
        <Stack
            key={message.key}
            width="90%"
            direction="row"
            justifyContent={align}
        >
            <Stack direction="column" alignItems={align} spacing={0.5}>
                <MessageCloud side={side} content={content} type={type} />
                <Typography
                    fontWeight={400}
                    fontSize="12px"
                    lineHeight="18px"
                    sx={{ opacity: "50%" }}
                >
                    {time(dayjs(message.created))}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default TextPromptModal;
