import React from "react";

interface Props {
    icon: string;
    color?: string;
    size?: number | string;
    style?: React.CSSProperties;
}

const ColoredIcon = (props: Props) => {
    const { icon, size, style, color } = props;

    if (color)
        return (
            <div
                style={{
                    backgroundColor: color,
                    maskImage: `url(${icon})`,
                    maskRepeat: "no-repeat",
                    maskPosition: "center",
                    maskSize: size,
                    width: size,
                    height: size,
                    ...style,
                }}
            ></div>
        );
    else
        return (
            <div
                style={{
                    backgroundImage: `url(${icon})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: size,
                    width: size,
                    height: size,
                    ...style,
                }}
            ></div>
        );
};

export default ColoredIcon;
