import React, { createContext, useContext, ReactNode } from "react";
import { getAddressFromSubdomain } from "../utils/getAddress";

const AddressContext = createContext<string | null>(null);

export const AddressProvider = ({ children }: { children: ReactNode }) => {
    const address = getAddressFromSubdomain();
    console.log("AddressProvider initialized with:", address);

    return (
        <AddressContext.Provider value={address}>
            {children}
        </AddressContext.Provider>
    );
};

export const useAddress = () => {
    const context = useContext(AddressContext);
    if (!context) {
        throw new Error("useAddress must be used within an AddressProvider");
    }
    return context;
};
