import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";

const environment = getEnvironment();

export default class Contact {
    address: string;
    phone: string;
    contactEmail: string;
    instagram: string;

    constructor(
        address: string,
        phone: string,
        contactEmail: string,
        instagram: string
    ) {
        this.address = address;
        this.phone = phone;
        this.contactEmail = contactEmail;
        this.instagram = instagram;
    }

    public static readonly info = async (brand: string) =>
        (await axios
            .get(environment.api_url + "/" + brand + "/brand/contact")
            .then((res) => (res.status === 200 ? res.data : undefined))
            .catch(console.error)) as Contact | undefined;
}
