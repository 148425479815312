import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import Bg from "../Bg";
import Button from "../Button";
import Bookings from "./Bookings";
import ProfileInfo from "./ProfileInfo";
import Footer from "../Footer";
import Header from "../Header";
import { useCookies } from "react-cookie";
import { useAddress } from "../../contexts/AddressContext";
import { useNavigate } from "react-router-dom";

const Profile = () => {
    const navigate = useNavigate();
    const address = useAddress();

    const [cookies] = useCookies(["client_id"]);
    const { client_id } = cookies;

    const [tab, setTab] = useState<"Bookings" | "Profile Details">("Bookings");

    useEffect(() => {
        if (!client_id) {
            navigate(`/profile/login`);
            return;
        }
    }, [client_id, address, navigate]);

    return (
        <Stack
            direction="column"
            width="100%"
            height="100vh"
            justifyContent="center"
            alignItems="center"
        >
            <Bg />
            <Header />
            <Stack
                width="100%"
                height="100%"
                alignItems="center"
                spacing={2}
                paddingTop={1}
                overflow="scroll"
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    width="85%"
                    spacing={2}
                >
                    {["Bookings", "Profile Details"].map((x) => (
                        <Button
                            variant="small"
                            color={tab === x ? "primary" : "transparent"}
                            onClick={() =>
                                setTab(x as "Bookings" | "Profile Details")
                            }
                            sx={{ paddingX: 2 }}
                        >
                            {x}
                        </Button>
                    ))}
                </Stack>
                {tab === "Bookings" ? <Bookings /> : <ProfileInfo />}
            </Stack>
            <Footer tab="profile" />
        </Stack>
    );
};

export default Profile;
