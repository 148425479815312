import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";

const environment = getEnvironment();

interface BrandRaw {
    key: string;
    name: string;
    address: string;
    colorRed: number;
    colorGreen: number;
    colorBlue: number;
    description: string;
    booking: boolean;
    reschedule: boolean;
    appointmentReschedule: number;
    cancellation: boolean;
    appointmentCancellation: number;
    reminder: boolean;
    appointmentReminder: number;
    confirmation: boolean;
    appointmentConfirmation: number;
    logo?: string;
}

export default class Brand {
    key: string;
    name: string;
    address: string;
    colorRed: number;
    colorGreen: number;
    colorBlue: number;
    description: string;
    booking: boolean;
    reschedule: boolean;
    appointmentReschedule: number;
    cancellation: boolean;
    appointmentCancellation: number;
    reminder: boolean;
    appointmentReminder: number;
    confirmation: boolean;
    appointmentConfirmation: number;
    logo?: string;

    constructor(data: BrandRaw) {
        this.key = data.key;
        this.name = data.name;
        this.address = data.address;
        this.colorRed = data.colorRed;
        this.colorGreen = data.colorGreen;
        this.colorBlue = data.colorBlue;
        this.description = data.description;
        this.booking = data.booking;
        this.reschedule = data.reschedule;
        this.appointmentReschedule = data.appointmentReschedule;
        this.cancellation = data.cancellation;
        this.appointmentCancellation = data.appointmentCancellation;
        this.reminder = data.reminder;
        this.appointmentReminder = data.appointmentReminder;
        this.confirmation = data.confirmation;
        this.appointmentConfirmation = data.appointmentConfirmation;
        this.logo = data.logo;
    }

    public static readonly info = async (brand: string) => {
        const data = (await axios
            .get(environment.api_url + "/" + brand + "/brand")
            .then((res) => (res.status === 200 ? res.data : undefined))
            .catch(console.error)) as BrandRaw | undefined;
        if (data) return new Brand(data);
        return undefined;
    };

    public static readonly getManifest = async (
        brandName: string
    ): Promise<any> => {
        try {
            const response = await axios.get(
                `${environment.api_url}/${brandName}/manifest`
            );
            if (response.status === 200) {
                return response.data; // Manifest data
            }
            throw new Error(`Failed to fetch manifest for brand: ${brandName}`);
        } catch (error) {
            console.error(`Error fetching manifest: ${error}`);
            return undefined;
        }
    };

    /*
    public favicon = async (
        size: number
    ): Promise<{ data: { faviconUrl: string } } | undefined> => {
        if (size <= 0) {
            return undefined;
        }
        return await axios.get(
            environment.api_url +
                "/" +
                this.address +
                "/brand/favicon/" +
                size.toString()
        );
    };
    */
}
