import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import BgImage from "../BgImage";
import Header from "../Header";
import Bg from "../Bg";
import { useNavigate } from "react-router-dom";
import TabHeader from "../TabHeader";
import { useCookies } from "react-cookie";
import Location from "../../api/Location";
import { useAddress } from "../../contexts/AddressContext";

const AboutUs = () => {
    const navigate = useNavigate();
    const address = useAddress();

    const [cookies] = useCookies(["client_location_id"]);
    const { client_location_id } = cookies;

    const [bgImage, setBgImage] = useState<string | undefined>();
    const [text, setText] = useState<string[]>([]);

    useEffect(() => {
        if (!client_location_id) {
            navigate("/");
            return;
        }
        Location.info(address, client_location_id)
            .then((res) => {
                if (!res) return;
                setText((res.description ?? "").split("\n"));
                setBgImage(res.image);
            })
            .catch(console.error);
    }, [client_location_id, address, navigate]);

    return (
        <Stack
            direction="column"
            width="100%"
            height="100vh"
            justifyContent="center"
            alignItems="center"
        >
            {bgImage ? <BgImage image={bgImage} full /> : <Bg />}
            <Header />
            <TabHeader name="About Us" onClose={() => navigate(-1 || "/")} />
            <Stack
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                overflow="scroll"
                paddingY={10}
            >
                {text.map((x) => (
                    <Stack width="80%">
                        <Typography
                            fontWeight={400}
                            fontSize="14px"
                            lineHeight="18.2px"
                        >
                            {x}
                        </Typography>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
};

export default AboutUs;
