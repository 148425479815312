import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Box,
    ButtonBase,
    Grid,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import TabHeader from "../TabHeader";
import Button from "../Button";
import dayjs, { Dayjs } from "dayjs";
import ColoredIcon from "../ColoredIcon";
import ArrowLeft from "../../img/Arrow_Left.svg";
import ArrowRight from "../../img/Arrow_Right.svg";
import Service from "../../api/Service";
import { useCookies } from "react-cookie";
import { useLoaderData, useNavigate } from "react-router-dom";
import Bg from "../Bg";
import Header from "../Header";
import Schedule from "../../api/Schedule";
import { FreeDay } from "../../api/Schedule";
import { parseWithOffset, normalizeToOffset } from "../../utils/dayjsUtils";
import { useAddress } from "../../contexts/AddressContext";

export const loader = async (request: any) => {
    const service_id = await request.params.service_id;
    return { service_id };
};

const DateTimePicker = () => {
    const navigate = useNavigate();
    const address = useAddress();
    const service_id = (useLoaderData() as any).service_id as string;

    const theme = useTheme();

    const [cookies] = useCookies(["client_location_id"]);
    const { client_location_id } = cookies;

    const [duration, setDuration] = useState<number | null>(null);

    const today = useMemo(() => dayjs.utc().startOf("day"), []);
    const [currentDate, setCurrentDate] = useState(today.startOf("month"));
    const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>();
    const [content, setContent] = useState<(Dayjs | undefined)[]>([]);

    const [availableDays, setAvailableDays] = useState<FreeDay[]>([]);

    const [availableTime, setAvailableTime] = useState<Dayjs[]>([]);
    const [selectedTime, setSelectedTime] = useState<Dayjs | undefined>();

    useEffect(() => {
        sessionStorage.removeItem("selected_dateTime");
        Service.info(address, service_id)
            .then((res) => {
                if (!res) return;
                setDuration(res.duration);
            })
            .catch(console.error);
    }, [address, service_id]);

    useEffect(() => {
        if (duration === null) return;

        Schedule.getFreeSlots(
            address,
            client_location_id,
            currentDate.get("year"),
            currentDate.get("month") + 1, // 1-indexed month
            duration
        )
            .then((days) => {
                setAvailableDays(days || []);
                const todaySlots = days?.find(
                    (day) => day.date === today.format("YYYY-MM-DD")
                );

                // Today is a working day with free slots
                if (todaySlots && todaySlots.freeSlots.length > 0) {
                    setSelectedDate(today);
                } else {
                    // Find the closest day with slots
                    const nextAvailableDay = days?.find(
                        (day) => day.freeSlots.length > 0
                    );
                    setSelectedDate(
                        nextAvailableDay
                            ? dayjs(nextAvailableDay.date)
                            : undefined
                    );
                }
            })
            .catch(console.error);
    }, [address, client_location_id, duration, currentDate, today]);

    useEffect(() => {
        setSelectedTime(undefined);
        if (!selectedDate) {
            setAvailableTime([]);
            return;
        }

        const selectedDaySlots = availableDays.find(
            (day) => day.date === selectedDate.format("YYYY-MM-DD")
        );

        if (!selectedDaySlots || selectedDaySlots.freeSlots.length === 0) {
            setAvailableTime([]);
            setSelectedTime(undefined);
            return;
        } else {
            const timeSlots = selectedDaySlots.freeSlots.map(
                (slot) => parseWithOffset(slot.start) // Store the raw Dayjs object
            );
            setAvailableTime(timeSlots);
            //setSelectedTime(timeSlots[0]);
        }
    }, [availableDays, selectedDate, duration]);

    useEffect(() => {
        const startOfMonth = currentDate.startOf("month");

        const daysInMonth = currentDate.daysInMonth();
        //console.log("Days in Month:", daysInMonth);

        const updatedContent: (Dayjs | undefined)[] = [];

        // Add placeholders for days of the week before the first day
        const startDayOfWeek = (startOfMonth.day() + 6) % 7; // Clunky fix to start the calendar from Monday
        //console.log("Start Day of Week:", startDayOfWeek);

        for (let i = 0; i < startDayOfWeek; i++) {
            updatedContent.push(undefined);
        }

        // Add all actual days of the month
        for (let day = 1; day <= daysInMonth; day++) {
            updatedContent.push(startOfMonth.clone().set("date", day));
        }

        setContent(updatedContent);
    }, [currentDate]);

    const disabledDates = useCallback(
        (date: Dayjs) => {
            if (date.isBefore(today)) return true;

            const dayData = availableDays.find(
                (day) => day.date === date.format("YYYY-MM-DD")
            );

            return !dayData || dayData.freeSlots.length === 0;
        },
        [today, availableDays]
    );

    const onBook = useCallback(() => {
        if (
            selectedDate === undefined ||
            disabledDates(selectedDate) ||
            selectedTime === undefined
        ) {
            sessionStorage.removeItem("selected_dateTime");
            navigate("/services/" + service_id + "/confirmation");
            return;
        }

        sessionStorage.setItem(
            "selected_dateTime",
            selectedTime.format("YYYY-MM-DDTHH:mm:ssZ")
        );
        navigate("/services/" + service_id + "/confirmation");
    }, [selectedDate, selectedTime, disabledDates, service_id, navigate]);

    return (
        <Stack
            direction="column"
            width="100%"
            height="100vh"
            justifyContent="center"
            alignItems="center"
        >
            <Bg />
            <Header />
            <TabHeader
                name="Pick Time and Date"
                onClose={() => navigate(`/services/${service_id}`)}
            />
            <Stack
                className="force-dynamic-translation"
                height="100%"
                width="100%"
                alignItems="center"
                spacing={1}
            >
                <Stack
                    width="100%"
                    height="100%"
                    alignItems="center"
                    margin={2}
                    paddingBottom={5}
                    spacing={2}
                >
                    <Stack
                        direction="row"
                        width="90%"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography
                            fontWeight={600}
                            fontSize="14px"
                            lineHeight="18.2px"
                            color="#1A1A1A"
                            sx={{ opacity: "60%" }}
                        >
                            {currentDate.format("MMMM, YYYY")}
                        </Typography>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                        >
                            <ButtonBase
                                sx={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "12px",
                                }}
                                disabled={currentDate.isSame(
                                    today.startOf("month"),
                                    "month"
                                )}
                                onClick={() => {
                                    setSelectedDate(undefined);
                                    setCurrentDate(
                                        currentDate.subtract(1, "month")
                                    );
                                }}
                            >
                                <ColoredIcon
                                    icon={ArrowLeft}
                                    size="100%"
                                    color={
                                        currentDate.isSame(
                                            today.startOf("month"),
                                            "month"
                                        )
                                            ? "grey"
                                            : "black"
                                    }
                                />
                            </ButtonBase>
                            <ButtonBase
                                sx={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "12px",
                                }}
                                //disabled={availableDays.length === 0}
                                onClick={() => {
                                    setSelectedDate(undefined);
                                    setCurrentDate(currentDate.add(1, "month"));
                                }}
                            >
                                <ColoredIcon
                                    icon={ArrowRight}
                                    size="100%"
                                    color="black"
                                />
                            </ButtonBase>
                        </Stack>
                    </Stack>
                    <Box
                        width="100%"
                        height="1px"
                        minHeight="1px"
                        bgcolor={theme.palette.primary.main}
                    />
                    <Grid container columns={7} width="95%" rowSpacing={1}>
                        {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map(
                            (x, index) => (
                                <Grid
                                    key={index}
                                    item
                                    xs={1}
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Typography
                                        fontWeight={700}
                                        fontSize="14px"
                                        lineHeight="18.2px"
                                        sx={{ opacity: "60%" }}
                                    >
                                        {x}
                                    </Typography>
                                </Grid>
                            )
                        )}
                        {content.map((date, index) => (
                            <Grid
                                key={index}
                                item
                                xs={1}
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {date === undefined ? (
                                    <Box width="48px" height="48px" />
                                ) : (
                                    <DayView
                                        day={date}
                                        selected={date.isSame(
                                            normalizeToOffset(selectedDate),
                                            "day"
                                        )}
                                        current={date.isSame(today, "day")}
                                        onClick={() => setSelectedDate(date)}
                                        disabled={disabledDates(date)}
                                    />
                                )}
                            </Grid>
                        ))}
                    </Grid>
                    <Stack
                        direction="row"
                        paddingTop={2}
                        width="90%"
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <Typography
                            fontWeight={600}
                            fontSize="14px"
                            lineHeight="18.2px"
                        >
                            Available time
                        </Typography>
                    </Stack>
                    <Box
                        width="100%"
                        height="1px"
                        minHeight="1px"
                        bgcolor={theme.palette.primary.main}
                    />
                    {availableTime.length === 0 ? (
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            width="85%"
                            padding={2}
                            bgcolor="white"
                            borderRadius="14px"
                            boxShadow="0px 4px 17px 0px #0000000A"
                        >
                            <Typography
                                fontWeight={400}
                                fontSize="18px"
                                lineHeight="23.4px"
                                textAlign="center"
                            >
                                {selectedDate &&
                                availableDays.find(
                                    (day) =>
                                        day.date ===
                                        selectedDate.format("YYYY-MM-DD")
                                )
                                    ? "No available time on this date"
                                    : "No time travel is supported yet"}
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                            width="85%"
                            //height="30px"
                            padding={2}
                            bgcolor="white"
                            borderRadius="14px"
                            boxShadow="0px 4px 17px 0px #0000000A"
                            overflow="scroll"
                            //paddingTop="120px"
                            //marginBottom="-10px"
                        >
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="flex-start"
                                rowGap={2}
                                width="100%"
                            >
                                {availableTime.map((time, index) => (
                                    <Grid
                                        key={index}
                                        item
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        width="72px"
                                        xs={3}
                                    >
                                        <ButtonBase
                                            sx={{
                                                width: "72px",
                                                height: "48px",
                                                marginX: "5px",
                                                borderRadius: "14px",
                                                bgcolor:
                                                    theme.palette.primary.main,
                                                opacity: time.isSame(
                                                    selectedTime,
                                                    "minute"
                                                )
                                                    ? "100%"
                                                    : "40%",
                                            }}
                                            onClick={() => {
                                                setSelectedTime(time);
                                            }}
                                        >
                                            <Typography
                                                fontWeight={700}
                                                fontSize="18px"
                                                lineHeight="23.4px"
                                                textAlign="center"
                                                color="white"
                                            >
                                                {time.format("HH:mm")}
                                            </Typography>
                                        </ButtonBase>
                                    </Grid>
                                ))}
                            </Grid>
                        </Stack>
                    )}
                </Stack>
            </Stack>
            <Stack width="90%" position="absolute" bottom={30}>
                <Button variant="large" fullWidth onClick={onBook}>
                    {selectedDate && !disabledDates(selectedDate)
                        ? "Book"
                        : "Add me to Waitlist"}
                </Button>
            </Stack>
        </Stack>
    );
};

const DayView = (props: {
    day: Dayjs;
    selected: boolean;
    current: boolean;
    onClick: () => void;
    disabled?: boolean;
}) => {
    const theme = useTheme();

    const { day, selected, current, onClick, disabled } = props;

    if (selected) {
        return (
            <ButtonBase
                sx={{
                    width: "48px",
                    minWidth: "48px",
                    maxWidth: "48px",
                    height: "48px",
                    borderRadius: "14px",
                    bgcolor: theme.palette.primary.main,
                    opacity: disabled ? "20%" : "100%",
                }}
            >
                <Typography
                    fontWeight={700}
                    fontSize="18px"
                    lineHeight="23.4px"
                    color={disabled ? "black" : "white"}
                >
                    {day.get("date")}
                </Typography>
            </ButtonBase>
        );
    }
    return (
        <ButtonBase
            sx={{
                width: "48px",
                minWidth: "48px",
                maxWidth: "48px",
                height: "48px",
                borderRadius: "14px",
                border: current
                    ? `1px solid ${theme.palette.primary.main}`
                    : "none",
                opacity: disabled ? "20%" : "100%",
            }}
            onClick={onClick}
        >
            <Typography fontWeight={400} fontSize="18px" lineHeight="23.4px">
                {day.get("date")}
            </Typography>
        </ButtonBase>
    );
};

export default DateTimePicker;
