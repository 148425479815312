import { useEffect, useState } from "react";
import Brand from "../api/Brand";
import Location from "../api/Location";
import Color from "../modules/Color";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { useCookies } from "react-cookie";
import { subscribeToPushNotifications } from "../modules/PushSubscription";
import { getEnvironment } from "../utils/getEnvironment";

const env = getEnvironment();

export const useInitializeApp = (address: string) => {
    const [cookies, setCookie] = useCookies([
        "app_theme",
        "client_id",
        "client_location_id",
    ]);
    const [initialized, setInitialized] = useState(false);
    const [notFound, setNotFound] = useState(false);

    const handlePushSubscription = async (userToken: string) => {
        // Checking registered service workers for debugging
        console.log("Checking registered service workers...");
        const registrations = await navigator.serviceWorker.getRegistrations();
        console.log("Registered service workers:", registrations);

        if (!("serviceWorker" in navigator)) {
            console.error("Service Worker is not supported in this browser.");
            return;
        }

        try {
            const registration = await navigator.serviceWorker.ready;
            console.log("Service Worker ready:", registration);

            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: env.vapid_key,
            });

            console.log("Push subscription object generated:", subscription);

            // Send subscription to the server
            await subscribeToPushNotifications({
                user_id: userToken,
                subscription,
            });

            console.log("Push subscription registered successfully.");
        } catch (err) {
            console.error("Error in handlePushSubscription:", err);
        }
    };

    useEffect(() => {
        const initialize = async () => {
            try {
                if (initialized || notFound) {
                    console.log("Already initialized or not found.");
                    return;
                }

                console.log("Starting app initialization...");

                // Check for token in the URL
                const params = new URLSearchParams(window.location.search);
                const token = params.get("token");

                if (token) {
                    // Store token in cookies
                    setCookie("client_id", token, {
                        path: "/",
                        //maxAge: 60 * 60 * 24 * 7, // 1 week
                        sameSite: "strict",
                    });

                    // Clean up URL
                    params.delete("token");
                    const cleanUrl =
                        window.location.pathname +
                        (params.toString() ? `?${params.toString()}` : "");
                    window.history.replaceState(null, "", cleanUrl);
                }

                // Fetch brand info
                const brandInfo = await Brand.info(address);
                if (!brandInfo) {
                    setNotFound(true);
                    return;
                }

                // Fetch location
                const locations = await Location.list(address);
                if (locations && locations.length > 0)
                    setCookie("client_location_id", locations[0].key);

                // Adjust theming
                const color = new Color(
                    brandInfo.colorRed,
                    brandInfo.colorGreen,
                    brandInfo.colorBlue
                );
                setCookie("app_theme", color.toHex(), {
                    domain: "",
                    path: "/",
                });

                // Fetch manifest
                const manifestData = await Brand.getManifest(address);
                if (manifestData) {
                    // Apply manifest
                    const content = encodeURIComponent(
                        JSON.stringify(manifestData)
                    );
                    const url = `data:application/manifest+json,${content}`;

                    const link = document.getElementById(
                        "manifest-id"
                    ) as HTMLLinkElement;
                    if (url && link) link.href = url;

                    // Update favicon
                    let favicon = document.querySelector(
                        "link[rel~='icon']"
                    ) as HTMLLinkElement;
                    if (!favicon) {
                        favicon = document.createElement("link");
                        favicon.rel = "icon";
                        document
                            .getElementsByTagName("head")[0]
                            .appendChild(favicon);
                    }
                    favicon.href = manifestData.icons?.find(
                        (icon: any) => icon.sizes === "64x64"
                    )?.src;

                    // Register service worker
                    console.log("Registering service worker...");
                    serviceWorkerRegistration.register();
                }

                // Handle Push Subscription
                const { client_id } = cookies;
                if (client_id) {
                    console.log("Handling push subscription...");
                    await handlePushSubscription(client_id);
                }

                setInitialized(true);
                console.log("The app is initialized successfully");
            } catch (err) {
                console.error("Error initializing app:", err);
            }
        };

        initialize();
    }, [address, initialized, setCookie, notFound, cookies]);

    return { initialized, notFound };
};
