export const getAddressFromSubdomain = (): string | null => {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");

    // Handle "localhost" explicitly for local testing
    if (hostname === "localhost") {
        return "nt2411"; // Default local testing brand
    }

    // Handle subdomains, including cases like "brand1.localhost"
    if (parts.length > 2 || (parts.length === 2 && parts[1] === "localhost")) {
        return parts[0]; // Return the subdomain
    }
    return null; // No subdomain found
};
